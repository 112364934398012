import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BillSettelmetColumnDataGrid,
  columns,
  MobileColumnDataGrid,
} from "./columns";
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectLatitude,
  selectLongitude,
  selectPrefix,
  selectWidth,
} from "../utility/Redux/profile";
import {
  editBillFields,
  GetListItemMaster,
  LoadBill,
  onBillSave,
  SectionlistUI,
  itemClick,
  listSectionMasterApi,
  generateThermalPDF,
  connectPrinterAndList,
  generatePDF,
} from "./function";
import { selectToken } from "../utility/Redux/security";
import moment from "moment";
import AccountMasterHelp from "../help/listAccountMasterHelp";
import CustomerMasterHelp from "../help/customermasterHelp";
import logoPng from "../utility/Logo/icons/orangeIcon.png";
import Billhelp from "../help/billHelp.js";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton.js";
import { selectSectionList } from "../utility/Redux/list.js";
import ViewBillModal from "./viewBillModal.js";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import SectionBillhelp from "./comp.js";
import {
  selectIssueItem,
  setAlertWithTitle,
  setnumericKeyboardStatus,
  setSpinnerLoading,
} from "../utility/Redux/modal.js";
import ModNumericKeyboard from "../utility/component/modalNumeric.js";
import { useFocus } from "../utility/hooks/hooks.js";
import BarcodeScanner from "../utility/component/barcodeScanner.js";
import InputModal from "../utility/component/Inputmodal.js";
import {
  fastMovingMovieItem,
  getItemByAlios,
  getItemByCode,
  isPosMod,
} from "../utility/constant.js";
import {
  billCart,
  billNoComp,
  CallAPIPromise,
  itemListComp,
  mobileViewWidth,
  searchItemInput,
  totalQntyComponent,
} from "./common.js";
import deleteWholeDatabase from "../utility/functions/deleteWholeDatabase.js";
import OnlyBillModal from "./onlyBillingModal.js";
import PendingBillHelp from "../help/pendingBillHelp.js";
import ItemVarientsHelp from "../help/itemVarientsHelp.js";
import dataToLocal from "../utility/component/dataToLocal.js";
import IsLocalData from "../utility/component/isLocalData.js";
import { useLocation, useNavigate } from "react-router-dom";
import ItemInstructionModal from "./itemInstruction.js";
import ViewBill from "./viewBill.js";
import ViewBillImage from "./viewBillImage.js";
import { useReactToPrint } from "react-to-print";
import qz from "qz-tray";
import html2canvas from "html2canvas";
import DraftBillImage from "./darftPrintImg.js";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FormatNumber2 } from "../utility/functions/formatNumber.js";
import NumberToWords3 from "../utility/component/numberToFormat.js";
import { toProperCase } from "../utility/component/format.js";
import BillingSettingsModal from "./settingModal.js";

const Billing = () => {
  const width = useSelector(selectWidth);
  const previosInstruction = localStorage.getItem("item_instruction")
    ? JSON.parse(localStorage.getItem("item_instruction"))
    : [];
  const [settingModalStatus, setSettingModalStatus] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);

  const location = useLocation();
  const [billStatus, setBillStatus] = useState(false);
  const [printData, setPrintData] = useState([]);
  const naviagte = useNavigate();
  const prefix = useSelector(selectPrefix);
  const [onlyBillingModalStatus, setOnlyBilliongModalStatus] = useState(false);
  const token = useSelector(selectToken);
  const [Item_Box, setItem_Box] = useState("");
  const [remarks, setRemarks] = useState("");
  const [orderList, setOrderList] = useState([]);
  const componentRef = useRef();
  const [selectedItem, setSelectedItem] = useState("");
  const [remarksStatus, setRemarksStatus] = useState(false);
  const [searchQnty, setSearchQnty] = useState(1);
  const [itemImgList, setItemImgList] = useState([]);
  const [viewBillStatus, setViewBillStatus] = useState(false);
  const [pendingBillHelpStatsus, setPendingBillStatus] = useState(false);
  const dispatch = useDispatch();
  const [lastCustomerDiscountrate, setLastCustomerDiscountrate] = useState(0);
  const [isHoldButtonShow, setIsHoldButonShow] = useState(true);
  const [isTextActive, setisTextActive] = useState(false);
  const [salepriceModalStatus, setSalePriceModalStatus] = useState(false);
  const [searchRef, setSearchFocus] = useFocus();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [billCartList, setBillCartList] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const sectionList = useSelector(selectSectionList);
  const [isOriginalBill, setIsOriginalBill] = useState(false);
  const [listAccountMasterHelp, setListAccountMasterHelp] = useState(false);
  const [customermasetrHelp, setCustomermasetrHelp] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [isSectionDrawerOpen, setIsSectionDraweropen] = useState(false);
  const [billHelpStatus, setBillHelpStatus] = useState(false);
  const [billNo, setBillNo] = useState("");
  const [grandTotalWithTax, setGrandTotalWithTax] = useState(0);
  const [barCodeCannerStatus, setBarCodeScannerStatus] = useState(false);
  const [roundOffAmt, setRoundOffAmt] = useState(0);
  const [oldBillIDDB, setOldBillIDDB] = useState([]);
  const longitude = useSelector(selectLongitude);
  const latitude = useSelector(selectLatitude);
  const bill_performa = "bills";
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const [currentItemsPage, setCurrentItemPage] = useState(1);
  const [totalItemsPage, setTotalItemsPage] = useState(1);
  const profileSetting = useSelector(selectDefultProfileSetting);
  const emptyCustomer = {
    CUSTOMER_NAME: "",
    CUSTOMER_ADDRESS: "",
    CUSTOMER_MOBILE: "",
    CUSTOMER_EMAIL: "",
    CUSTOMER_TYPE: "",
    CITY: "",
    COUNTRY: "",
    PIN_CODE: "",
    DISQ_RATE: "",
    LOYALTY_POINTS: 0,
    CUSTOMER_STATUS: "Active",
    PRV_BAL: 0,
    BAL_TYPE: "",
    CUSTOMER_CODE: "",
  };
  const [customerDetails, setCustomerDetails] = useState(emptyCustomer);
  const emptyBillingDetails = {
    mobile: "",
    customerName: "",
    cashAmount: 0,
    cardAmount: 0,
    cardNo: "",
    eAmount: 0,
    btcAmount: 0,
    btcName: "",
    couponAmount: 0,
    couponNo: "",
    HANDLING_CHARGES: 0,
    DELIVERY_CHARGES: 0,
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const comapnyAddress = useSelector(selectCompanyAddress);
  const loadImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Important if loading from an external URL
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png")); // Convert to Base64
      };
      img.onerror = (error) => reject(error);
    });
  };

  const [billingDetails, setBillingDetails] = useState(emptyBillingDetails);

  const [SaleType, setSaleType] = useState("");
  const [isStockCheck, setIsStockCheck] = useState("Yes");

  useEffect(() => {
    setSaleType(profileSetting?.SALE_TYPE);
    setIsStockCheck(profileSetting?.CHECK_STOCK === "No" ? false : true);
  }, [profileSetting]);

  const [itemSearchText, setItemSearchText] = useState("");
  const [itemList, setItemList] = useState([]);
  const c = columns(
    width,
    setBillCartList,
    setAlertTwoButtonModal,
    setRemarksStatus,
    setSelectedItem
  );
  const MobileColumn = MobileColumnDataGrid(
    width,
    setBillCartList,
    setAlertTwoButtonModal
  );

  useEffect(() => {
    if (!sectionList[0] && navigator.onLine) {
      listSectionMasterApi(prefix, token, "", dispatch);
    }
  }, []);

  const TotalQnty = billCartList.reduce(
    (acc, row) => acc + Number(row.QNTY),
    0
  );
  const returnItem = billCartList.filter((e) => e.RQNTY > 0);
  const TotalAmount = billCartList.reduce(
    (acc, row) => acc + row.NET_AMOUNT,
    0
  );
  const totalDiscount = billCartList.reduce(
    (acc, row) => acc + row.DISQ_AMT,
    0
  );
  const totalReturn = billCartList.reduce(
    (acc, row) => acc + Number(row.RQNTY),
    0
  );

  const totalReturnAmount = returnItem.reduce(
    (acc, row) => acc + row.NET_AMOUNT,
    0
  );

  const grandTotal =
    TotalAmount -
    totalReturnAmount +
    parseFloat(billingDetails.DELIVERY_CHARGES) +
    parseFloat(billingDetails.HANDLING_CHARGES);

  useEffect(() => {
    setBillCartList((e) => {
      return e.map((i) => {
        return {
          ...i,
          CUSTOMER_CODE: 0,
          CUSTOMER_NAME: customerDetails.CUSTOMER_NAME,
          CUSTOMER_ADDRESS: customerDetails.CUSTOMER_ADDRESS,
          CUSTOMER_MOBILE: customerDetails.CUSTOMER_MOBILE,
          CUSTOMER_EMAIL: customerDetails.CUSTOMER_EMAIL,
          CARD_NO: billingDetails.cardNo,
          CARD_NAME: "",
          SALES_MAN: "",
          CASH_AMOUNT: billingDetails.cashAmount,
          CARD_AMOUNT: billingDetails.cardAmount,
          BTC_AMOUNT: billingDetails.btcAmount,
          COUPAN_AMOUNT: billingDetails.couponAmount,
          E_AMOUNT: billingDetails.eAmount,
          COUPAN_NO: billingDetails.couponNo,

          HANDLING_CHARGES: billingDetails.HANDLING_CHARGES,
          DELIVERY_CHARGES: billingDetails.DELIVERY_CHARGES,
          ITEM_INSTRUCTIONS: i?.ITEM_INSTRUCTIONS ? i.ITEM_INSTRUCTIONS : "",
        };
      });
    });
  }, [customerDetails, billingDetails]);

  const Undo = () => {
    setCustomerDetails({
      CUSTOMER_NAME: "",
      CUSTOMER_ADDRESS: "",
      CUSTOMER_MOBILE: "",
      CUSTOMER_EMAIL: "",
      CUSTOMER_TYPE: "",
      CITY: "",
      COUNTRY: "",
      PIN_CODE: "",
      DISQ_RATE: "",
      LOYALTY_POINTS: 0,
      CUSTOMER_STATUS: "Active",
      PRV_BAL: 0,
      BAL_TYPE: "",
    });
    setBillingDetails(emptyBillingDetails);
    setBillCartList([]);
    setSearchFocus();
    setItemSearchText("");
    setSectionName("");
    setIsHoldButonShow(true);
  };

  const issueItemDetails = useSelector(selectIssueItem);

  const fromref = useRef(null);
  const searchBoxRef = useRef(null);
  const inputFields = fromref?.current?.querySelectorAll("input, select");
  const searchBoxInuptRef = searchBoxRef?.current?.querySelectorAll("input");

  const isVoided = (I) => {
    const l = billCartList.filter((s) => s.ICODE === I.ICODE && s.RQNTY > 0);
    if (l[0]) {
      return {
        backgroundColor: "#FAA8A8",
        color: "black",
      };
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (SaleType?.toLowerCase() !== "inclusive") {
      const list = billCartList.filter((o) => o.RQNTY < 1);
      const listWithTax = list.map((e) => {
        let saleAmount = parseFloat(e.AMOUNT);
        let disqAmount = parseFloat(e.DISQ_AMT);

        let tax1Rate = parseFloat(e.TAX1_RATE);
        let tax2Rate = parseFloat(e.TAX2_RATE);
        let tax3Rate = parseFloat(e.TAX3_RATE);

        let tax1_amt = 0;
        let tax2_amt = 0;
        let tax3_amt = 0;
        let saleAmount11 = saleAmount - disqAmount;
        if (
          !isNaN(saleAmount) &&
          !isNaN(tax1Rate) &&
          !isNaN(tax2Rate) &&
          !isNaN(tax3Rate)
        ) {
          let tax1amt = (saleAmount11 * tax1Rate) / 100;
          tax1_amt = tax1amt.toFixed(2);
          tax2Rate = (saleAmount11 * tax2Rate) / 100;
          tax2_amt = tax2Rate.toFixed(2);
          let tax3amt = (saleAmount11 * tax3Rate) / 100;
          tax3_amt = tax3amt.toFixed(2);
        }
        return {
          ...e,
          TAX1_AMT: tax1_amt,
          TAX2_AMT: tax2_amt,
          TAX3_AMT: tax3_amt,
        };
      });

      const totalTax1 = listWithTax.reduce(
        (acc, row) => acc + Number(row.TAX1_AMT),
        0
      );
      const totalTax2 = listWithTax.reduce(
        (acc, row) => acc + Number(row.TAX2_AMT),
        0
      );
      const totalTax3 = listWithTax.reduce(
        (acc, row) => acc + Number(row.TAX3_AMT),
        0
      );
      const totalWithTax = grandTotal + totalTax1 + totalTax2 + totalTax3;
      const roundedTotal = Math.round(totalWithTax); // Rounds to nearest whole number
      const roundOffAmount = (roundedTotal - totalWithTax).toFixed(2); // Difference between
      setRoundOffAmt(roundOffAmount);

      setGrandTotalWithTax(
        (parseFloat(totalWithTax) + parseFloat(roundOffAmount)).toFixed(2)
      );
    } else {
      const roundedTotal = Math.round(grandTotal); // Rounds to nearest whole number
      const roundOffAmount = (roundedTotal - grandTotal).toFixed(2); // Difference between
      setRoundOffAmt(roundOffAmount);
      setGrandTotalWithTax(
        (parseFloat(grandTotal) + parseFloat(roundOffAmount)).toFixed(2)
      );
    }
  }, [grandTotal]);

  useEffect(() => {
    if (width > 1280) {
      const handleClickOutside = (event) => {
        if (
          event?.target.tagName.toLowerCase() !== "input " &&
          event?.target.tagName.toLowerCase() !== "textarea " &&
          !isTextActive &&
          !onlyBillingModalStatus &&
          !billHelpStatus &&
          !salepriceModalStatus &&
          !settingModalStatus
        ) {
          searchBoxInuptRef?.forEach((element) => {
            console.log(width > 1280);
            console.log(element.id);
            if (element.id === "search" && width > 1280) {
              element.focus();
            }
          });
        }
      };

      document.addEventListener("click", handleClickOutside);

      handleClickOutside();

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
    // Focus on the search input when any other click event occurs
  }, [
    billCartList,
    itemList,
    selectedItem,
    isTextActive,
    onlyBillingModalStatus,
    billHelpStatus,
    salepriceModalStatus,
    width,
    settingModalStatus,
  ]);

  const remainingTotal =
    Number(grandTotalWithTax) -
    (Number(billingDetails.cardAmount) +
      Number(billingDetails.eAmount) +
      Number(billingDetails.btcAmount) +
      Number(billingDetails.couponAmount));
  useEffect(() => {
    if (
      billingDetails.btcAmount < 1 &&
      billingDetails.couponAmount < 1 &&
      billingDetails.cardAmount < 1 &&
      billingDetails.eAmount < 1
    )
      setBillingDetails((o) => {
        return {
          ...o,
          cashAmount: remainingTotal,
        };
      });
  }, [remainingTotal]);

  useEffect(() => {
    GetListItemMaster(
      prefix,
      fastMovingMovieItem,
      token,
      dispatch,
      "",
      "",
      setItemList,
      currentItemsPage,
      setTotalItemsPage
    ).then((list) => {
      if (!list[0]) {
        GetListItemMaster(
          prefix,
          "",
          token,
          dispatch,
          "",
          "",
          setItemList,
          currentItemsPage,
          setTotalItemsPage
        );
      }
    });
  }, []);

  const onChangePagination = (page) => {
    setCurrentItemPage(page);
    GetListItemMaster(
      prefix,
      sectionName,
      token,
      dispatch,
      "",
      "",
      setItemList,
      page,
      setTotalItemsPage
    );
  };

  useEffect(() => {
    if (totalDiscount < 1 || lastCustomerDiscountrate > 0) {
      if (customerDetails?.DISQ_RATE > 0) {
        editBillFields(
          {
            field: "DISQ_RATE_ALL",
            value: 0,
            id: 1,
          },
          setBillCartList,
          dispatch,
          billCartList,
          billingDetails,
          customerDetails,
          setAlertTwoButtonModal,
          setBillingDetails,
          profileSetting,
          setCustomerDetails
        );
      } else {
        if (lastCustomerDiscountrate !== 0) {
          editBillFields(
            {
              field: "DISQ_RATE_ALL",
              value: 0,
              id: 1,
            },
            setBillCartList,
            dispatch,
            billCartList,
            billingDetails,
            customerDetails,
            setAlertTwoButtonModal,
            setBillingDetails,
            profileSetting,
            setCustomerDetails
          );

          setLastCustomerDiscountrate(0);
        }
      }
    }
  }, [customerDetails]);

  useEffect(() => {
    if (location?.state?.INVNO) {
      const isRemoveINVNO = true;
      LoadBill(
        prefix,
        token,
        dispatch,
        location?.state?.INVNO,
        setBillCartList,
        setCustomerDetails,
        setBillingDetails,
        setDate,
        "orders",
        isRemoveINVNO
      );
      setPendingBillStatus(false);
    } else {
      const callback = (details, data) => {
        if (data[0]) {
          setAlertTwoButtonModal({
            title: "Unsaved Data",
            msg: "We found an unsaved bill. Do you want to load it?",
            status: true,
            button1: "Yes",
            button2: "Cancel",
            button1Click: () => {
              setBillCartList(data);

              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });
            },

            button2Click: () => {
              deleteWholeDatabase("billing");
              setAlertTwoButtonModal((o) => {
                return {
                  ...o,
                  status: false,
                };
              });
            },
          });
        }
      };
      const elseFunc = () => {
        console.log("nothing");
      };
      IsLocalData("billing", callback, elseFunc);
    }
  }, [location?.state]);
  console.log(printData);

  const onClickPrintBill = async (bill) => {
    const defaultPrinter = localStorage.getItem("printer");
    const defaultSize = localStorage.getItem("paperSize");
    const billData = bill ? bill : printData;

    if (defaultSize && defaultPrinter) {
      if (defaultSize === "58-210") {
        const pdfData = await generateThermalPDF(isOriginalBill, billData);

        const config = qz.configs.create(defaultPrinter); // Change to your printer name
        const data = [
          {
            type: "pdf",
            format: "base64",
            data: pdfData.split(",")[1], // Remove `data:application/pdf;base64,`
          },
        ];

        qz.print(config, data)
          .then(() => setPrintData([]))
          .catch((err) => console.error("Print failed: ", err));
      }

      if (defaultSize === "A4") {
        const pdfData = await generatePDF(isOriginalBill, billData);

        const config = qz.configs.create(defaultPrinter); // Change to your printer name
        const data = [
          {
            type: "pdf",
            format: "base64",
            data: pdfData.split(",")[1], // Remove `data:application/pdf;base64,`
          },
        ];

        qz.print(config, data)
          .then(() => setPrintData([]))
          .catch((err) => console.error("Print failed: ", err));
      }
    } else {
      setSettingModalStatus(true);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#edf2f9" /* light gray */,
      }}
      className="  h-full  text-black flex  text-sm w-full"
    >
      {width > mobileViewWidth ? (
        <div className="h-full p-2 flex w-full">
          <div className="h-full">
            {billNoComp(
              setBillHelpStatus,
              billCartList,
              date,
              profileSetting,
              dispatch,
              setSettingModalStatus
            )}
            <div
              style={{
                height: `calc(100% - ${42 + 10 + 30 + 15}px)`,
                width: c.reduce((acc, row) => acc + Number(row.width), 0),
              }}
              className="mt-2 shadow-sm rounded-sm  overflow-hidden "
            >
              {billCart(c, billCartList, dispatch, isVoided)}
            </div>
            {totalQntyComponent(
              c,
              TotalQnty,
              totalReturn,
              billCartList,
              TotalAmount,
              totalReturnAmount,
              width,
              grandTotalWithTax,
              false,
              dispatch
            )}
            {/* {billSettelemntBilling(fromref, c, setisTextActive, customerDetails, TotalAmount, setCustomerDetails, customerList, prefix, token, setCustomerList, dispatch, setBillingDetails, inputFields, setCustomermasetrHelp, grandTotalWithTax, width, billingDetails, setListAccountMasterHelp,remainingTotal)} */}
          </div>
          <div className="flex  grow h-full  ml-2 ">
            <div className="w-full h-full ">
              <div
                style={{
                  height: `calc(100% - 80px)`,
                }}
                className="flex w-full "
              >
                <div
                  style={{
                    width:
                      width > 1280 ? `calc(100% - 230px)` : `calc(100% - 5px)`,
                  }}
                  className="h-full flex flex-col "
                >
                  {searchItemInput(
                    searchBoxRef,
                    searchRef,
                    width,
                    setBarCodeScannerStatus,
                    itemSearchText,
                    billCartList,
                    dispatch,
                    setBillCartList,
                    billingDetails,
                    customerDetails,
                    setAlertTwoButtonModal,
                    isStockCheck,
                    setSearchQnty,
                    setItem_Box,
                    setSalePriceModalStatus,
                    setItemSearchText,
                    prefix,
                    token,
                    setItemList,
                    setSectionName,
                    profileSetting,
                    setTotalItemsPage,
                    currentItemsPage
                  )}
                  {width <= 1280 && (
                    <div className="mt-2">
                      <SectionBillhelp
                        horizontal={true}
                        setIsSectionDraweropen={setIsSectionDraweropen}
                        isSectionDrawerOpen={isSectionDrawerOpen}
                        sectionList={sectionList}
                        sectionName={sectionName}
                        setSectionName={setSectionName}
                        GetListItemMaster={GetListItemMaster}
                        setItemList={setItemList}
                        currentItemsPage={currentItemsPage}
                        setTotalItemsPage={setTotalItemsPage}
                      />
                    </div>
                  )}

                  {itemListComp(
                    itemList,
                    billCartList,
                    setBillCartList,
                    billingDetails,
                    customerDetails,
                    dispatch,
                    setAlertTwoButtonModal,
                    isStockCheck,
                    setItem_Box,
                    setSalePriceModalStatus,
                    itemImgList,
                    prefix,
                    token,
                    setSectionName,
                    setItemList,
                    width,
                    profileSetting,
                    totalItemsPage,
                    currentItemsPage,
                    onChangePagination
                  )}
                </div>
                {width > 1280 ? (
                  <SectionlistUI
                    sectionList={sectionList}
                    sectionName={sectionName}
                    setSectionName={setSectionName}
                    prefix={prefix}
                    token={token}
                    setSectio
                    dispatch={dispatch}
                    currentItemsPage={currentItemsPage}
                    setTotalItemsPage={setTotalItemsPage}
                    setItemList={setItemList}
                    setCurrentItemPage={setCurrentItemPage}
                  />
                ) : null}
              </div>

              <div
                style={{
                  justifyContent:
                    width >= 1400 ? "space-between" : "space-between",
                  // backgroundColor: "#e0e5ec", // Light gray background
                  // boxShadow:
                  //   "inset 8px 8px 15px #c8d1e3, inset -8px -8px 15px #ffffff", // Inset shadow for neumorphism effect
                }}
                className="  pt-3"
              >
                <div
                  // style={{
                  //   display: width >= 1400 ? "flex" : "none",
                  // }}
                  className=" items-center grid grid-cols-6 gap-2"
                >
                  <div>
                    <button
                      onClick={async () => {
                        const phoneRegex = /^[6-9]\d{9}$/;
                        if (
                          (customerDetails.CUSTOMER_MOBILE &&
                            phoneRegex.test(customerDetails.CUSTOMER_MOBILE)) ||
                          !customerDetails.CUSTOMER_MOBILE
                        ) {
                          const TotalreceivedAmt = Number(grandTotalWithTax);

                          if (TotalreceivedAmt >= Number(grandTotalWithTax)) {
                            if (
                              TotalreceivedAmt === Number(grandTotalWithTax)
                            ) {
                              onBillSave(
                                billCartList.map((b) => {
                                  return {
                                    ...b,
                                    CASH_AMOUNT: TotalreceivedAmt,
                                    CARD_AMOUNT: 0,
                                    BTC_AMOUNT: 0,
                                    COUPAN_AMOUNT: 0,
                                    E_AMOUNT: 0,
                                  };
                                }),
                                dispatch,
                                prefix,
                                token,
                                Undo,
                                grandTotalWithTax,
                                setBillNo,
                                setAlertTwoButtonModal,
                                setViewBillStatus,
                                inputFields,
                                SaleType,
                                "",
                                "",
                                longitude,
                                latitude,
                                false,
                                customerDetails,
                                setIsOriginalBill,
                                roundOffAmt,
                                location?.state?.INVNO,
                                naviagte,
                                setPrintData,
                                onClickPrintBill
                              );
                            } else {
                              dispatch(
                                setAlertWithTitle({
                                  title: "Alert",
                                  msg: `Recived Amount cannot be greater then Bill amount.`,
                                })
                              );
                            }
                          } else {
                            inputFields.forEach((element) => {
                              if (element.id === "Cash_amount") {
                                element.focus();
                              }
                            });
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: `Bill Settlement not cleared.`,
                              })
                            );
                          }
                        } else {
                          inputFields.forEach((element) => {
                            if (element.id === "Customer_mobile") {
                              element.focus();
                            }
                          });
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "Customer mobile is Invalid.",
                            })
                          );
                        }
                      }}
                      className="bg-[#E4B07B] px-2 w-full py-2 rounded-lg  items-center justify-center transform hover:scale-105 transition-all duration-300 ease-in-out"
                    >
                      <i className="bi bi-cash text-xl "></i>
                      <div>Cash</div>
                    </button>
                  </div>

                  <div>
                    <button
                      className="bg-[#87CBB9] w-full px-2 py-2 rounded-lg  items-center justify-center transform hover:scale-105 transition-all duration-300 ease-in-out"
                      onClick={async () => {
                        const phoneRegex = /^[6-9]\d{9}$/;
                        if (
                          (customerDetails.CUSTOMER_MOBILE &&
                            phoneRegex.test(customerDetails.CUSTOMER_MOBILE)) ||
                          !customerDetails.CUSTOMER_MOBILE
                        ) {
                          const TotalreceivedAmt = Number(grandTotalWithTax);

                          if (TotalreceivedAmt >= Number(grandTotalWithTax)) {
                            if (
                              TotalreceivedAmt === Number(grandTotalWithTax)
                            ) {
                              onBillSave(
                                billCartList.map((b) => {
                                  return {
                                    ...b,
                                    CASH_AMOUNT: 0,
                                    CARD_AMOUNT: TotalreceivedAmt,
                                    BTC_AMOUNT: 0,
                                    COUPAN_AMOUNT: 0,
                                    E_AMOUNT: 0,
                                  };
                                }),
                                dispatch,
                                prefix,
                                token,
                                Undo,
                                grandTotalWithTax,
                                setBillNo,
                                setAlertTwoButtonModal,
                                setViewBillStatus,
                                inputFields,
                                SaleType,
                                "",
                                "",
                                longitude,
                                latitude,
                                false,
                                customerDetails,
                                setIsOriginalBill,
                                roundOffAmt,
                                location?.state?.INVNO,
                                naviagte,
                                setPrintData,
                                onClickPrintBill
                              );
                            } else {
                              dispatch(
                                setAlertWithTitle({
                                  title: "Alert",
                                  msg: `Recived Amount cannot be greater then Bill amount.`,
                                })
                              );
                            }
                          } else {
                            inputFields.forEach((element) => {
                              if (element.id === "Cash_amount") {
                                element.focus();
                              }
                            });
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: `Bill Settlement not cleared.`,
                              })
                            );
                          }
                        } else {
                          inputFields.forEach((element) => {
                            if (element.id === "Customer_mobile") {
                              element.focus();
                            }
                          });
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "Customer mobile is Invalid.",
                            })
                          );
                        }
                      }}
                    >
                      <i className="bi bi-credit-card text-xl "></i>
                      <div>Card</div>
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={async () => {
                        const phoneRegex = /^[6-9]\d{9}$/;
                        if (
                          (customerDetails.CUSTOMER_MOBILE &&
                            phoneRegex.test(customerDetails.CUSTOMER_MOBILE)) ||
                          !customerDetails.CUSTOMER_MOBILE
                        ) {
                          const TotalreceivedAmt = Number(grandTotalWithTax);

                          if (TotalreceivedAmt >= Number(grandTotalWithTax)) {
                            if (
                              TotalreceivedAmt === Number(grandTotalWithTax)
                            ) {
                              onBillSave(
                                billCartList.map((b) => {
                                  return {
                                    ...b,
                                    CASH_AMOUNT: 0,
                                    CARD_AMOUNT: 0,
                                    BTC_AMOUNT: 0,
                                    COUPAN_AMOUNT: 0,
                                    E_AMOUNT: TotalreceivedAmt,
                                  };
                                }),
                                dispatch,
                                prefix,
                                token,
                                Undo,
                                grandTotalWithTax,
                                setBillNo,
                                setAlertTwoButtonModal,
                                setViewBillStatus,
                                inputFields,
                                SaleType,
                                "",
                                "",
                                longitude,
                                latitude,
                                false,
                                customerDetails,
                                setIsOriginalBill,
                                roundOffAmt,
                                location?.state?.INVNO,
                                naviagte,
                                setPrintData,
                                onClickPrintBill
                              );
                            } else {
                              dispatch(
                                setAlertWithTitle({
                                  title: "Alert",
                                  msg: `Recived Amount cannot be greater then Bill amount.`,
                                })
                              );
                            }
                          } else {
                            inputFields.forEach((element) => {
                              if (element.id === "Cash_amount") {
                                element.focus();
                              }
                            });
                            dispatch(
                              setAlertWithTitle({
                                title: "Alert",
                                msg: `Bill Settlement not cleared.`,
                              })
                            );
                          }
                        } else {
                          inputFields.forEach((element) => {
                            if (element.id === "Customer_mobile") {
                              element.focus();
                            }
                          });
                          dispatch(
                            setAlertWithTitle({
                              title: "Alert",
                              msg: "Customer mobile is Invalid.",
                            })
                          );
                        }
                      }}
                      className="bg-[#A3D9A5] w-full  px-2 py-2 rounded-lg  items-center justify-center transform hover:scale-105 transition-all duration-300 ease-in-out"
                    >
                      <i className="bi bi-wallet2 text-xl "></i>
                      <div>E-Wallet</div>
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => setOnlyBilliongModalStatus(true)}
                      className="bg-[#3E3E3E] text-white w-full py-2 rounded-lg  items-center justify-center transform hover:scale-105 transition-all duration-300 ease-in-out"
                    >
                      <i className="bi bi-receipt text-xl"></i>
                      <div>Settle Bill</div>
                    </button>
                  </div>

                  {printData[0]?.INVNO && (
                    <div>
                      <button
                        onClick={onClickPrintBill}
                        className="bg-[#93C5FD] w-full text-black  px-2 py-2 rounded-lg  items-center justify-center transform hover:scale-105 transition-all duration-300 ease-in-out"
                      >
                        <i className="bi bi-printer text-xl"></i>
                        <div>Print Bill</div>
                      </button>
                    </div>
                  )}
                  {isHoldButtonShow && (
                    <div>
                      <button
                        onClick={() => {
                          if (billCartList[0]) {
                            const isPending = true;
                            onBillSave(
                              billCartList,
                              dispatch,
                              prefix,
                              token,
                              Undo,
                              grandTotalWithTax,
                              setBillNo,
                              setAlertTwoButtonModal,
                              setViewBillStatus,
                              inputFields,
                              SaleType,
                              "",
                              "",
                              longitude,
                              latitude,
                              isPending,
                              customerDetails,
                              setIsOriginalBill,
                              roundOffAmt,
                              location?.state?.INVNO,
                              naviagte,
                              setPrintData,
                              onClickPrintBill
                            );
                          } else {
                            setPendingBillStatus(true);
                          }
                        }}
                        className="bg-red-300 w-full  px-2 py-2 rounded-lg  items-center justify-center  transform hover:scale-105 transition-all duration-300 ease-in-out"
                      >
                        <i className="bi bi-pause text-xl "></i>
                        <div>{billCartList[0] ? "Hold" : "Show Hold"}</div>
                      </button>
                    </div>
                  )}

                  <div>
                    <button
                      onClick={() => {
                        Undo();
                        setPrintData([]);
                      }}
                      className="bg-brown  px-2 py-2 w-full rounded-lg  items-center justify-center  text-white transform hover:scale-105 transition-all duration-300 ease-in-out"
                    >
                      <i className="bi bi-arrow-counterclockwise text-xl "></i>{" "}
                      <div>Undo</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full p-2 h-full">
          <div>
            {billNoComp(
              setBillHelpStatus,
              billCartList,
              date,
              profileSetting,
              dispatch,
              setSettingModalStatus
            )}
          </div>
          <div
            style={{
              height: `calc(100% - 130px)`,
            }}
            className="mt-2"
          >
            <div className="h-full flex">
              <div className=" h-full">
                <div
                  style={{
                    height: `calc(100% - 100px)`,
                  }}
                >
                  {billCart(MobileColumn, billCartList, dispatch, isVoided)}
                </div>
                {totalQntyComponent(
                  MobileColumn,
                  TotalQnty,
                  totalReturn,
                  billCartList,
                  TotalAmount,
                  totalReturnAmount,
                  width,
                  grandTotalWithTax,
                  false,
                  dispatch
                )}
              </div>
              {
                <div className="flex-1 ml-1  h-full">
                  <SectionBillhelp
                    setIsSectionDraweropen={setIsSectionDraweropen}
                    isSectionDrawerOpen={isSectionDrawerOpen}
                    sectionList={sectionList}
                    sectionName={sectionName}
                    setSectionName={setSectionName}
                    GetListItemMaster={GetListItemMaster}
                    setItemList={setItemList}
                  />
                  <div
                    style={{
                      height: `calc(100% - 92px)`,
                    }}
                  >
                    <div className="pt-2">
                      {searchItemInput(
                        searchBoxRef,
                        searchRef,
                        width,
                        setBarCodeScannerStatus,
                        itemSearchText,
                        billCartList,
                        dispatch,
                        setBillCartList,
                        billingDetails,
                        customerDetails,
                        setAlertTwoButtonModal,
                        isStockCheck,
                        setSearchQnty,
                        setItem_Box,
                        setSalePriceModalStatus,
                        setItemSearchText,
                        prefix,
                        token,
                        setItemList,
                        setSectionName,
                        profileSetting
                      )}
                    </div>
                    {itemListComp(
                      itemList,
                      billCartList,
                      setBillCartList,
                      billingDetails,
                      customerDetails,
                      dispatch,
                      setAlertTwoButtonModal,
                      isStockCheck,
                      setItem_Box,
                      setSalePriceModalStatus,
                      itemImgList,
                      prefix,
                      token,
                      setSectionName,
                      setItemList,
                      width,
                      profileSetting,
                      totalItemsPage,
                      currentItemsPage,
                      onChangePagination
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="flex justify-end w-full mt-2">
            <div>
              <button
                style={{
                  boxShadow: "1px 1px 4px #bebebe, -1px -1px 4px #ffffff",
                }}
                onClick={() => setOnlyBilliongModalStatus(true)}
                className="bg-green w-[110px] py-2 rounded-sm "
              >
                Bill Settlement
              </button>
            </div>
            {isHoldButtonShow && (
              <div>
                <button
                  style={{
                    boxShadow: "1px 1px 4px #bebebe, -1px -1px 4px #ffffff",
                  }}
                  onClick={() => {
                    if (billCartList[0]) {
                      const isPending = true;
                      onBillSave(
                        billCartList,
                        dispatch,
                        prefix,
                        token,
                        Undo,
                        grandTotalWithTax,
                        setBillNo,
                        setAlertTwoButtonModal,
                        setViewBillStatus,
                        inputFields,
                        SaleType,
                        "",
                        "",
                        longitude,
                        latitude,
                        isPending,
                        customerDetails,
                        setIsOriginalBill,
                        roundOffAmt,
                        location?.state?.INVNO,
                        naviagte,
                        setPrintData,
                        onClickPrintBill
                      );
                    } else {
                      setPendingBillStatus(true);
                    }
                  }}
                  className="bg-red-300 w-[110px] py-2 rounded-sm ml-2"
                >
                  {billCartList[0] ? "Hold" : "Show Hold Bill"}
                </button>
              </div>
            )}
            <div>
              <button
                onClick={Undo}
                style={{
                  boxShadow: "1px 1px 4px #bebebe, -1px -1px 4px #ffffff",
                }}
                className="bg-brown ml-2 w-[100px] py-2 rounded-sm text-white "
              >
                Undo
              </button>
            </div>
          </div>
          <div></div>
        </div>
      )}
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        maxSnack={2}
        autoHideDuration={1000}
      />
      <ViewBillModal
        status={viewBillStatus}
        billNo={billNo}
        bill_performa={bill_performa}
        onBack={() => {
          setViewBillStatus(false);
        }}
        hideVoid={true}
        isOriginalBill={isOriginalBill}
      />
      <AccountMasterHelp
        onRowClick={(e) => {
          setBillCartList((list) =>
            list.map((item) => {
              return {
                ...item,
                ACCOUNT_NO: e.row.ACCOUNT_NO,
                ADDRESS: e.row.ADDRESS,
                NAME: e.row.NAME,
              };
            })
          );
          setBillingDetails((b) => {
            return {
              ...b,
              btcName: e.row.NAME,
            };
          });
          setListAccountMasterHelp(false);
        }}
        onClose={() => {
          setListAccountMasterHelp(false);
        }}
        status={listAccountMasterHelp}
      />
      <CustomerMasterHelp
        onClose={() => {
          setCustomermasetrHelp(false);
        }}
        status={customermasetrHelp}
        onRowClick={(e) => {
          setCustomerDetails(e.row);
          setCustomermasetrHelp(false);
          setBillingDetails((o) => {
            return {
              ...o,
              btcName: e.row.CUSTOMER_NAME,
            };
          });
        }}
      />
      <OnlyBillModal
        roundOffAmt={roundOffAmt}
        fromref={fromref}
        onClickPrintBill={onClickPrintBill}
        setIsOriginalBill={setIsOriginalBill}
        setViewBillStatus={setViewBillStatus}
        emptyBillingDetails={emptyBillingDetails}
        emptyCustomer={emptyCustomer}
        longitude={longitude}
        latitude={latitude}
        SaleType={SaleType}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
        setBillNo={setBillNo}
        Undo={Undo}
        billCartList={billCartList}
        remainingTotal={remainingTotal}
        setListAccountMasterHelp={setListAccountMasterHelp}
        billingDetails={billingDetails}
        grandTotalWithTax={grandTotalWithTax}
        setCustomermasetrHelp={setCustomermasetrHelp}
        inputFields={inputFields}
        setBillingDetails={setBillingDetails}
        setCustomerList={setCustomerList}
        customerList={customerList}
        setCustomerDetails={setCustomerDetails}
        TotalAmount={TotalAmount}
        customerDetails={customerDetails}
        setisTextActive={setisTextActive}
        columns={c}
        setPrintData={setPrintData}
        status={onlyBillingModalStatus}
        editBillFields={editBillFields}
        totalDiscount={totalDiscount}
        setBillCartList={setBillCartList}
        profileSetting={profileSetting}
        setLastCustomerDiscountrate={setLastCustomerDiscountrate}
        lastCustomerDiscountrate={lastCustomerDiscountrate}
        onClose={() => {
          setOnlyBilliongModalStatus(false);
        }}
        isCrossOrder={location?.state?.INVNO}
      />
      <InputModal
        setItem_Box={setItem_Box}
        Item_Box={Item_Box}
        placeholder={"Sale Price"}
        onCancel={() => {
          setSearchQnty(1);
          setItem_Box("");
          setSalePriceModalStatus(false);
        }}
        onSave={(e) => {
          const b = {
            ...Item_Box,
            SALE_PRICE: e,
          };

          itemClick(
            b,
            b,
            setBillCartList,
            billingDetails,
            customerDetails,
            searchQnty,
            dispatch,
            setAlertTwoButtonModal,
            false,
            isStockCheck,
            prefix,
            token,
            true,
            profileSetting,
            billCartList
          );
          setItem_Box("");
          setSearchQnty("");
          setSalePriceModalStatus(false);
        }}
        okButtonText={"Add"}
        status={salepriceModalStatus}
        title={"Add Sale price"}
        desc={`New Sale Price`}
        type={"number"}
      />
      <ModNumericKeyboard
        onChange={(e) => {
          editBillFields(
            e,
            setBillCartList,
            dispatch,
            billCartList,
            billingDetails,
            customerDetails,
            setAlertTwoButtonModal,
            setBillingDetails,
            profileSetting,
            setCustomerDetails
          );
        }}
        editType={""}
        mode={"dark"}
        extraButtons={
          <button
            onClick={() => {
              if (issueItemDetails) {
                setAlertTwoButtonModal({
                  title: "Alert",
                  msg: "Are you sure for remove this item?",
                  button1: "Yes",
                  button2: "Cancel",
                  status: true,

                  button1Click: () => {
                    setAlertTwoButtonModal((o) => {
                      return {
                        ...o,
                        status: false,
                      };
                    });

                    setBillCartList((e) => {
                      const data = e.filter(
                        (i) => i.ICODE !== issueItemDetails.id
                      );
                      deleteWholeDatabase("billing").then(() => {
                        dataToLocal("billing", data, [
                          {
                            ICODE: 1,
                          },
                        ]);
                      });
                      return data;
                    });
                    dispatch(setnumericKeyboardStatus(false));
                    setAlertTwoButtonModal((e) => {
                      return {
                        ...e,
                        status: false,
                      };
                    });
                  },
                  button2Click: () => {
                    setAlertTwoButtonModal((o) => {
                      return {
                        ...o,
                        status: false,
                      };
                    });
                  },
                });
              }
            }}
            className="bg-[#242323] mt-2  rounded-sm  flex w-full justify-center text-red-500 py-1"
          >
            Remove Item
          </button>
        }
      />
      <BillingSettingsModal
        open={settingModalStatus}
        onClose={() => {
          setSettingModalStatus(false);
        }}
        onClickPrintBill={onClickPrintBill}
        printData={printData}
      />
      <BarcodeScanner
        onChange={(e) => {
          setItemSearchText(e);
          setBarCodeScannerStatus(false);
          getItemByAlios(prefix, e, token, dispatch).then((list) => {
            if (list[0]) {
              const filterd = billCartList.filter(
                (item) => item.ICODE === list[0].ICODE && item.RQNTY > 0
              );
              if (filterd[0]) {
                enqueueSnackbar(`Not Permitted`, {
                  variant: "warning",
                });
                dispatch(
                  setAlertWithTitle({
                    title: "Alert",
                    msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                    horizontal: true,
                  })
                );
              } else {
                if (list[0].SALE_PRICE > 0) {
                  itemClick(
                    list[0],
                    list[0],
                    setBillCartList,
                    billingDetails,
                    customerDetails,
                    1,
                    dispatch,
                    setAlertTwoButtonModal,
                    false,
                    isStockCheck,
                    prefix,
                    token,
                    false,
                    profileSetting,
                    billCartList
                  );
                } else {
                  const isInBillCart = billCartList.filter(
                    (t) => t.ICODE === list[0].ICODE
                  );
                  if (isInBillCart[0]) {
                    const data = {
                      ...list[0],
                      SALE_PRICE: isInBillCart[0].SALE_PRICE,
                    };

                    itemClick(
                      data,
                      data,
                      setBillCartList,
                      billingDetails,
                      customerDetails,
                      1,
                      dispatch,
                      setAlertTwoButtonModal,
                      false,
                      isStockCheck,
                      prefix,
                      token,
                      false,
                      profileSetting,
                      billCartList
                    );
                  } else {
                    setItem_Box(list[0]);

                    setSalePriceModalStatus(true);
                  }
                }
              }
              setItemSearchText("");
            } else {
              getItemByCode(prefix, e, token, dispatch).then((list) => {
                if (list[0]) {
                  const filterd = billCartList.filter(
                    (item) => item.ICODE === list[0].ICODE && item.RQNTY > 0
                  );
                  if (filterd[0]) {
                    enqueueSnackbar(`Not Permitted`, {
                      variant: "warning",
                    });
                    dispatch(
                      setAlertWithTitle({
                        title: "Alert",
                        msg: "You cannot enter a value for Qnty after entering Return. Please remove the value from Return before entering Qnty.",
                        horizontal: true,
                      })
                    );
                  } else {
                    if (list[0].SALE_PRICE > 0) {
                      itemClick(
                        list[0],
                        list[0],
                        setBillCartList,
                        billingDetails,
                        customerDetails,
                        1,
                        dispatch,
                        setAlertTwoButtonModal,
                        false,
                        isStockCheck,
                        prefix,
                        token,
                        false,
                        profileSetting,
                        billCartList
                      );
                    } else {
                      const isInBillCart = billCartList.filter(
                        (t) => t.ICODE === list[0].ICODE
                      );
                      if (isInBillCart[0]) {
                        const data = {
                          ...list[0],
                          SALE_PRICE: isInBillCart[0].SALE_PRICE,
                        };

                        itemClick(
                          data,
                          data,
                          setBillCartList,
                          billingDetails,
                          customerDetails,
                          1,
                          dispatch,
                          setAlertTwoButtonModal,
                          false,
                          isStockCheck,
                          prefix,
                          token,
                          false,
                          profileSetting,
                          billCartList
                        );
                      } else {
                        setItem_Box(list[0]);

                        setSalePriceModalStatus(true);
                      }
                    }
                  }
                  setItemSearchText("");
                } else {
                  enqueueSnackbar(`Item Not Found.`, {
                    variant: "error",
                  });
                  setItemSearchText("");
                }
              });
            }
          });
        }}
        setStatus={setBarCodeScannerStatus}
        status={barCodeCannerStatus}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <img src={previewImage} />
      <div
        style={
          {
            // display: "none",
          }
        }
      >
        {/* <DraftBillImage
          bill_performa={bill_performa}
          isOriginalBill={isOriginalBill}
          setBillStatus={setBillStatus}
          setOrderList={setOrderList}
          ref={componentRef}
          billNo={billNo}
          data={printData}
        /> */}
      </div>

      <Billhelp
        onRowClick={(p) => {
          LoadBill(
            prefix,
            token,
            dispatch,
            p.row.INVNO,
            setBillCartList,
            setCustomerDetails,
            setBillingDetails,
            setDate,
            bill_performa,
            false,
            setPrintData
          ).then((l) => {
            setPrintData(l);
          });
          setIsHoldButonShow(false);
          setBillHelpStatus(false);
        }}
        status={billHelpStatus}
        onClose={() => setBillHelpStatus(false)}
      />

      <PendingBillHelp
        status={pendingBillHelpStatsus}
        onClose={() => {
          setPendingBillStatus(false);
        }}
        onRowClick={(p) => {
          LoadBill(
            prefix,
            token,
            dispatch,
            p.row.INVNO,
            setBillCartList,
            setCustomerDetails,
            setBillingDetails,
            setDate,
            bill_performa
          );
          setPendingBillStatus(false);
        }}
      />
      <ItemVarientsHelp
        billingDetails={billingDetails}
        customerDetails={customerDetails}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
        isStockCheck={isStockCheck}
        setBillCartList={setBillCartList}
      />
      {remarksStatus && (
        <ItemInstructionModal
          remarksStatus={remarksStatus}
          setRemarksStatus={setRemarksStatus}
          remarks={remarks}
          title={"About order"}
          placeHolder={"Remarks"}
          setRemarks={setRemarks}
          previosInstruction={previosInstruction}
          onFocus={() => {
            setisTextActive(true);
          }}
          selectedItem={selectedItem}
          billCartList={billCartList}
          onBlur={() => {
            setisTextActive(false);
          }}
          onSave={() => {
            setRemarksStatus(false);
            setBillCartList((o) => {
              return o.map((or) => {
                if (or.ICODE === selectedItem) {
                  return {
                    ...or,
                    ITEM_INSTRUCTIONS: remarks,
                  };
                } else {
                  return or;
                }
              });
            });

            const isDuplicate = previosInstruction.filter(
              (o) => o.toLowerCase() === remarks.toLowerCase()
            );
            if (!isDuplicate[0]) {
              localStorage.setItem(
                "item_instruction",
                JSON.stringify([...previosInstruction, remarks])
              );
            }

            setRemarks("");
          }}
          buttonName={"Save"}
        />
      )}
    </div>
  );
};

export default Billing;
