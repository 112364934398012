import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Lottie from "lottie-react";
import CryptoJS from "crypto-js";
import { Country, State, City } from "country-state-city";
import {
  selectLatitude,
  selectLongitude,
  selectWidth,
  setlatitudeRedux,
  setlongitudeRedux,
} from "../../utility/Redux/profile";
import {
  setAlertWithTitle,
  setEmailVerificationStatus,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import CallAPI from "../../utility/functions/getData";
import AlertModal2 from "../../utility/component/modals/alertWithLottie";
import register from "../../utility/json/reisterCompany.json";
import { CallAPIPromise } from "../../billing/common";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import { companyTypeList } from "../../utility/constant";
import { getCompanyTypeList } from "../../utility/functions/common";
import SpinnerLoading from "../../utility/component/modals/spinnerLoading";
const RegisterCompany = () => {
  const emptyData = {
    company_name: "",
    company_email: "",
    company_website: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pin_code: "",
    company_mobile: "",
    user_name: "",
    business_type: "",
    user_email: "",
    user_password: "",
    user_type: "MANAGER",
    user_status: "ACTIVE",
    group_name: "",
    shop_id: "",
    shop_name: "",
    currency_name: "rupee",
    currency_symbol: "₹",
    regd_no1: "",
    regd_no2: "",
    regd_no3: "",
    disputes: "",
    company_logo: "",
    table_prefix: "",
    latitude: "",
    longitude: "",
    // user_scope: "read:orders,write:orders",
    user_role: "Manager",
  };
  const [formData, setFormData] = useState(emptyData);
  const countries = Country.getAllCountries();
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const dispatch = useDispatch();
  const langitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);
  const phoneRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const [activeTab, setActiveTab] = useState("companyInfo");
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [termAccepted, setTermsAccepted] = useState(false);
  const dispacth = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "company_email") {
      checkIsDuplicateUser(value);
    }
    if (name === "company_mobile") {
      checkIsDuplicateUser("", value);
    }
  };

  const clearState = () => {
    setFormData(emptyData);
  };

  const createFinacialYear = (code, company, email, hmac) => {
    const data = {
      company_code: code,
      company_name: company,
      company_email: email,
    };
    CallAPIPromise(
      `/api/users/createFinancialYear`,

      data,
      hmac,
      dispacth
    )
      .then(async function (response) {
        navigate("/auth/login", {
          state: {
            signupEmail: email,
          },
        });
        dispatch(setEmailVerificationStatus(true));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleSubmit = () => {
    if (
      !formData.company_name ||
      !formData.company_email ||
      !formData.company_website ||
      !formData.address ||
      !formData.country ||
      !formData.state ||
      !formData.city ||
      !formData.pin_code ||
      !formData.company_mobile ||
      !formData.user_name ||
      !formData.user_email ||
      !formData.user_password ||
      !formData.business_type ||
      !formData.confirmPassword
    ) {
      setError("All fields are required!");

      // Focus on the first empty field
      const firstEmptyField = Object.keys(formData).find(
        (key) => !formData[key]
      );

      if (firstEmptyField === "country") {
        setError("Country required!");
      }
      if (
        firstEmptyField === "user_name" ||
        firstEmptyField === "user_email" ||
        firstEmptyField === "user_password"
      ) {
        setActiveTab("userInfo");
      } else {
        setActiveTab("companyInfo");
      }
      if (firstEmptyField === "state") {
        setError("State required!");
      }
      if (firstEmptyField === "city") {
        setError("City required!");
      }

      setTimeout(() => {
        setError(""); // Clear the error after 4 seconds
      }, 4000);

      setTimeout(() => {
        if (firstEmptyField) {
          const input = document.querySelector(
            `input[name=${firstEmptyField}]`
          );
          if (input) {
            input.focus(); // Focus on the first empty field
          }
        }
      }, 500);

      return;
    }

    if (formData.user_password !== formData.confirmPassword) {
      setError("Passwords do not match!");
      setTimeout(() => {
        setError("");
      }, 4000);
      return;
    }

    if (formData.user_password.length < 5) {
      setError("Password must be at least 5 characters");
      setTimeout(() => {
        setError("");
      }, 4000);
      return;
    }

    if (
      !emailRegex.test(formData.user_email) ||
      !emailRegex.test(formData.company_email)
    ) {
      setError("Please enter a valid email");
      setTimeout(() => {
        setError("");
      }, 4000);
      return;
    }

    if (!termAccepted) {
      setError("Accept Terms & Conditions");
      setTimeout(() => {
        setError("");
      }, 4000);
      return;
    }

    const text = formData.company_name;
    const words = text.split(" ");

    const result = words.reduce((acc, word) => {
      if (word) {
        acc += word[0];
      }
      return acc;
    }, "");
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(formData.user_name, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const tablePrefix = result.toLowerCase();

    const data = {
      user_email: formData.user_email,
      user_password: formData.user_password,
      user_name: formData.user_name,
      user_type: "MANAGER",
      user_status: "ACTIVE",
      company_email: formData.company_email,
      company_name: formData.company_name,
      company_mobile: formData.company_mobile,
      company_website: formData.company_website,
      group_name: "",
      address: formData.address,
      city: formData.city,
      state: formData.state,
      pin_code: formData.pin_code,
      shop_id: "",
      shop_name: "",
      currency_name: "rupee",
      currency_symbol: "₹",
      regd_no1: "",
      regd_no2: "",
      regd_no3: "",
      disputes: `All Disputes Subject to Jalandhar`,
      company_logo: "",
      table_prefix: tablePrefix,
      latitude: langitude,
      longitidue: longitude,
      user_role: "Manager",
      business_type: formData.business_type,
      is_terms_conditions_accepted: "Yes",
    };
    dispatch(setSpinnerLoading("Creating"));
    CallAPI(
      "/api/users/registerCompany",
      data,
      hmacString,
      (e) => {
        if (!e.data.error) {
          const code = e.data.results.insertId;
          createFinacialYear(
            code,
            formData.company_name,
            formData.company_email,
            hmacString
          );

          clearState();
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Something Went Wrong.",
              type: "warning",
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
    setError("");
  };
  const checkIsDuplicateUser = (email, mobile) => {
    if (email || mobile) {
      const isEmail = email && emailRegex.test(email);
      const isMobile = mobile && phoneRegex.test(mobile);
      if (isEmail || isMobile) {
        const u = email ? email : mobile;
        const hmac = CryptoJS.HmacSHA256(u, secretPass);
        // Convert the HMAC to a string and encode it using Base64
        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        const d = {
          company_email: email ? email : "",
          company_mobile: mobile ? mobile : "",
        };
        CallAPI(
          "/api/users/companyInfo",
          d,
          hmacString,
          (e) => {
            if (e.data.response[0]) {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: `This ${
                    email ? "email address" : "mobile"
                  } is already registered with our system`,
                  type: "warning",
                })
              );
              if (email) {
                setFormData((o) => {
                  return {
                    ...o,
                    company_email: "",
                    company_mobile: "",
                  };
                });
              } else {
                setFormData((o) => {
                  return {
                    ...o,
                    company_mobile: "",
                    company_mobile: "",
                  };
                });
              }
            }
          },
          dispatch
        );
      }
    }
  };
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setlatitudeRedux(latitude));
          dispatch(setlongitudeRedux(longitude));
          // reverseGeocode(latitude, longitude); // Get address for current location
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  const width = useSelector(selectWidth);
  useEffect(() => {
    if (formData.country) {
      const countryy = countries.filter(
        (country) => country.name === formData.country
      );
      setStatelist(State.getStatesOfCountry(countryy[0].isoCode));
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state && stateList[0]) {
      const statee = stateList.filter((state) => state.name === formData.state);
      // Check if statee has at least one element
      if (statee.length > 0) {
        const firstState = statee[0];
        setCitylist(
          City.getCitiesOfState(firstState.countryCode, firstState.isoCode)
        );
      } else {
        // alert("hgdcfujhgsd");
        // setCitylist([]);
      }
    }
  }, [formData.state, stateList]);

  useEffect(() => {
    handleUseCurrentLocation();
  }, []);

  useEffect(() => {
    getCompanyTypeList().then((types) => {
      setCompanyTypeList(types);
    });
  }, []);
  return signUp_New(
    width,
    handleSubmit,
    error,
    successMessage,
    formData,
    handleChange,
    countries,
    stateList,
    cityList,
    activeTab,
    setActiveTab,
    navigate,
    companyTypeList,
    termAccepted,
    setTermsAccepted
  );
};

export default RegisterCompany;

function signUp_New(
  width,
  handleSubmit,
  error,
  successMessage,
  formData,
  handleChange,
  countries,
  stateList,
  cityList,
  activeTab,
  setActiveTab,
  navigate,
  companyTypeList,
  termAccepted,
  setTermsAccepted
) {
  return (
    <div className=" bg-gray-100 h-screen  w-screen overflow-y-auto">
      <Header navigate={navigate} />

      <div className="flex mt-[80px] container justify-center  items-center ">
        <div
          style={{
            display: width > 1024 ? "flex" : "none",
          }}
          className=" flex-1  "
        >
          <div
            style={{
              height: "785px",
            }}
            className="l:mr-10 flex flex-col l:justify-between  py-10"
          >
            <div className="">
              <div className="text-[28px] font-semibold text-gray-700">
                Register Your Company
              </div>
              <div className="text-gray-500 pt-2 max-w-[700px]">
                Join us in revolutionizing the way businesses operate. Our
                dedicated team is here to support you every step of the way.
              </div>
            </div>

            <div className="w-[420px]  ">
              <Lottie
                animationData={register}
                loop={true}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
        </div>
        <div
          onSubmit={handleSubmit}
          className="border rounded-lg bg-white shadow-md ms:p-3  md:p-6 max-w-2xl w-full  "
        >
          <div className="h-[29px] ">
            {error && <p className="text-red-500 text-sm  rounded ">{error}</p>}
          </div>

          <div className="flex md:hidden   mb-3  border-black justify-center ">
            <button
              onClick={() => {
                setActiveTab("companyInfo");
              }}
              className={`${
                activeTab === "companyInfo" ? "bg-[#383535] text-white" : ""
              } py-1 s  rounded-sm flex-1 text-center `}
            >
              Company Info
            </button>

            <button
              onClick={() => {
                setActiveTab("userInfo");
              }}
              className={`${
                activeTab === "userInfo" ? "bg-[#383535] text-white" : ""
              } py-1  ml-2  rounded-sm flex-1  text-center`}
            >
              User Info
            </button>
          </div>

          {activeTab === "companyInfo" || width > 767 ? (
            <>
              <h2 className=" text-blue-500 mt-3 md:mt-0 ms:hidden md:flex text-lg font-semibold    rounded-md  items-center">
                Company Information
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 m gap-3">
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Company Name
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      name="company_name"
                      value={formData.company_name}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full py-2 pl-[30px]  focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-building position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Company Email
                  </label>
                  <div className="position-relative">
                    <input
                      type="email"
                      name="company_email"
                      value={formData.company_email}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full py-2 pl-[30px] focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-envelope-at position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Bussiness Type
                  </label>
                  <div className="position-relative">
                    <div type="option">
                      <select
                        name="business_type"
                        value={formData.business_type}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-md w-full py-2 pl-[30px]  focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                        required
                      >
                        <option>Select Company Type</option>
                        {companyTypeList.map((c) => {
                          return (
                            <option
                              label={c.business_type}
                              value={c.business_type}
                            />
                          );
                        })}
                      </select>
                    </div>
                    <i
                      className={`bi bi-shop position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500`}
                    ></i>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Address
                  </label>
                  <div className="position-relative">
                    <input
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full py-2 pl-[30px]  focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-geo-alt position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-1">
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Country
                      </label>
                      <select
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                        required
                      >
                        <option value="">Select Country</option>
                        {countries.map((e, i) => (
                          <option key={i} value={e.value}>
                            {e.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="ml-2 flex flex-1">
                    <div className="w-full">
                      <label className="block text-gray-700 font-semibold mb-1">
                        State
                      </label>
                      <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                        required
                      >
                        <option value="">Select State</option>
                        {stateList.map((e, i) => (
                          <option key={i} value={e.value}>
                            {e.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex flex-1">
                    <div className="w-full">
                      <label className="block text-gray-700 font-semibold mb-1">
                        City
                      </label>
                      <select
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                        required
                      >
                        <option value="">Select City</option>
                        {cityList.map((e, i) => (
                          <option key={i} value={e.value}>
                            {e.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="ml-2 flex flex-1">
                    <div className="w-full">
                      <label className="block text-gray-700 font-semibold mb-1">
                        Pincode
                      </label>
                      <input
                        type="text"
                        name="pin_code"
                        value={formData.pin_code}
                        onChange={handleChange}
                        className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Mobile
                  </label>
                  <div className="position-relative">
                    <input
                      type="tel"
                      name="company_mobile"
                      value={formData.company_mobile}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full py-2 pl-[30px]  focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-telephone position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Website
                  </label>
                  <div className="position-relative">
                    <input
                      type="url"
                      name="company_website"
                      value={formData.company_website}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full py-2 pl-[30px]  focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-globe position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {activeTab === "userInfo" || width > 767 ? (
            <>
              <h2 className=" text-blue-500 md:mt-3 ms:hidden md:flex text-lg font-semibold   rounded-md  items-center">
                User Information
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {/* Username */}
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Username
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      name="user_name"
                      value={formData.user_name}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full p-2 ps-4 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-person position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>

                {/* User Email */}
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    User Email
                  </label>
                  <div className="position-relative">
                    <input
                      type="email"
                      name="user_email"
                      value={formData.user_email}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full p-2 ps-4 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-envelope-at position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>

                {/* Password */}
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Password
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      name="user_password"
                      value={formData.user_password}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full p-2 ps-4 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-lock position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>

                {/* Confirm Password */}
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    Confirm Password
                  </label>
                  <div className="position-relative">
                    <input
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full p-2 ps-4 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                    <i className="bi bi-lock-fill position-absolute top-50 translate-middle-y start-0 ms-2 text-gray-500"></i>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="flex text-sm items-center mt-2">
            <div>
              <input
                onChange={(e) => setTermsAccepted(e.target.checked)}
                type="checkbox"
                checked={termAccepted}
              />
            </div>
            {console.log()}
            <div
              onClick={() => {
                window.open(window.location.origin + "/terms_conditions");
              }}
              className={"ml-2 cursor-pointer "}
            >
              Accept Term & Conditions
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={handleSubmit}
              className="bg-[#f88f09] text-white font-semibold py-3 rounded-md w-full hover:bg-[#e77c05] transition duration-200"
            >
              Register Company
            </button>
            <div className="flex justify-end mt-3 text-sm">
              Allready have an account
              <button
                onClick={() => {
                  navigate("/auth/login");
                }}
                className="ml-2 text-orange font-semibold"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <SpinnerLoading />
      <AlertModal2 />
    </div>
  );
}
