import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyAddress } from "../../Redux/profile";
import "./printCss.css";
import moment from "moment";
import { FormatNumber, FormatNumber2 } from "../../functions/formatNumber";
const InvoiceTemplate = ({
  data,
  rows,
  partyDetails,
  roundOff,
  grandTotal,
  hsnSummary,
}) => {
  const { sellerInfo, invoiceDetails, items, totals, terms, columns } = data;
  const companyDetails = useSelector(selectCompanyAddress);
  const cgsttotal = rows.reduce((acc, row) => acc + Number(row.TAX1_AMT), 0);
  const sgstTotal = rows.reduce((acc, row) => acc + Number(row.TAX2_AMT), 0);
  const igstTotal = rows.reduce((acc, row) => acc + Number(row.TAX3_AMT), 0);
  const totalTax = Number(cgsttotal) + Number(sgstTotal) + Number(igstTotal);
  const surchargeTotal = rows.reduce(
    (acc, row) => acc + Number(row.SURCHARGE_AMT),
    0
  );

  const taxTotal = hsnSummary.reduce((acc, row) => acc + Number(row.TOTAL), 0);
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        fontSize: "10px",
      }}
    >
      <div
        id="print-component"
        style={{
          border: "1px solid black",
          margin: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            fontSize: "13px",
            fontWeight: "600",
            padding: 5,
            borderBottom: "1px solid black",
          }}
        >
          Retail Purchase (Duplicate Copy)
        </div>
        {/* Header Section */}
        <div style={{ textAlign: "center", padding: 5 }}>
          <div
            style={{ fontWeight: "bold", fontSize: "15px" }}
            className="uppercase"
          >
            {companyDetails?.companyName}
          </div>
          <div style={{ fontSize: "10px" }}>
            {companyDetails?.address +
              ", " +
              companyDetails?.city +
              ", " +
              companyDetails?.state +
              ", " +
              companyDetails?.mobile}
          </div>
          <div
            style={{
              fontWeight: "600",
            }}
          >
            GST No. : {companyDetails.regdNo}
          </div>
        </div>
        <div
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
          className="flex"
        >
          <div
            style={{
              borderRight: "1px solid black",
              paddingLeft: 5,
            }}
            className="flex"
          >
            <div
              style={{
                width: "100px",
              }}
              className="mr-1"
            >
              Invoice No.
            </div>{" "}
            :<div className="px-2">{invoiceDetails.invoiceNo}</div>
          </div>

          <div
            style={{
              borderRight: "1px solid black",
              paddingLeft: 5,
            }}
            className="flex"
          >
            <div
              style={{
                width: "100px",
              }}
              className="mr-1"
            >
              Purchase Bill No.
            </div>{" "}
            :<div className="px-2">{invoiceDetails.purchaseNo}</div>
          </div>

          <div
            style={{
              borderRight: "1px solid black",
              paddingLeft: 5,
            }}
            className="flex"
          >
            <div
              style={{
                width: "100px",
              }}
              className="mr-1"
            >
              Purchase Bill Date
            </div>{" "}
            :
            <div className="px-2">
              {moment(partyDetails.DATE).format("YYYY/MM/DD")}
            </div>
          </div>
        </div>

        {/* Seller Information */}
        <div style={{ padding: 5 }}>
          <div style={{ fontWeight: "bold" }}>{partyDetails.NAME}</div>
          <div>{sellerInfo.address}</div>
          <div>{partyDetails.MOBILE}</div>
          <div
            style={{
              fontWeight: "600",
            }}
          >
            GST NO. : {partyDetails.TAX_NUMBER}
          </div>
        </div>

        {/* Itemized Table */}
        <table
          style={{
            borderTop: "1px solid black",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              {columns.map((c, i) => (
                <th
                  style={{
                    borderRight:
                      i + 1 !== columns.length
                        ? "1px solid black"
                        : "0px solid black",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                  key={c.field}
                >
                  {c.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((r, index) => (
              <tr key={index}>
                {columns.map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        i + 1 !== columns.length
                          ? "1px solid black"
                          : "0px solid black",
                      borderTop: "1px solid black",
                      padding: "5px",
                      width: c.width,
                      textAlign: c.align ? c.align : "left",
                    }}
                    key={c.field}
                  >
                    {r[c.field]}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              {columns
                .filter((c) => {
                  if (c.field === "ICODE") {
                    return true;
                  }
                  if (c.field === "QNTY") {
                    return true;
                  }
                  if (c.field === "PUR_PRICE") {
                    return true;
                  }
                  if (c.field === "DISQ_RT") {
                    return true;
                  }
                  if (c.field === "DISQ_AMT") {
                    return true;
                  }
                  if (c.field === "AMOUNT") {
                    return true;
                  }
                  if (c.field === "TAX1_AMT") {
                    return true;
                  }
                  if (c.field === "TAX2_AMT") {
                    return true;
                  }
                  if (c.field === "TAX3_AMT") {
                    return true;
                  }
                  if (c.field === "TAX3_AMT") {
                    return true;
                  }
                  if (c.field === "CESS_AMT") {
                    return true;
                  }
                  if (c.field === "NET_AMT") {
                    return true;
                  }
                  if (c.field === "VAT_AMOUNT") {
                    return true;
                  }
                  return false;
                })
                .map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        c.field !== "NET_AMT"
                          ? "1px solid black"
                          : "0px solid black",
                      borderTop: "1px solid black",
                      padding: "5px",
                      width: c.width,
                      textAlign: "right",
                      fontWeight: "600",
                      backgroundColor: "#f0f2f5",
                    }}
                    colSpan={c.field === "ICODE" ? 3 : 1}
                  >
                    {c.field === "ICODE" && "Total"}
                    {c.field === "QNTY" &&
                      rows.reduce((acc, row) => acc + Number(row.QNTY), 0)}
                    {c.field === "AMOUNT" &&
                      rows
                        .reduce((acc, row) => acc + Number(row.AMOUNT), 0)
                        .toFixed(2)}
                    {c.field === "TAX1_AMT" &&
                      rows
                        .reduce((acc, row) => acc + Number(row.TAX1_AMT), 0)
                        .toFixed(2)}
                    {c.field === "TAX2_AMT" &&
                      rows
                        .reduce((acc, row) => acc + Number(row.TAX2_AMT), 0)
                        .toFixed(2)}
                    {c.field === "TAX3_AMT" &&
                      rows
                        .reduce((acc, row) => acc + Number(row.TAX3_AMT), 0)
                        .toFixed(2)}
                    {c.field === "VAT_AMOUNT" &&
                      rows
                        .reduce((acc, row) => acc + Number(row.VAT_AMOUNT), 0)
                        .toFixed(2)}
                    {c.field === "DISQ_AMT" && ""}
                    {c.field === "CESS_AMT" &&
                      rows
                        .reduce(
                          (acc, row) =>
                            acc + Number(row.CESS_AMT ? row.CESS_AMT : 0),
                          0
                        )
                        .toFixed(2)}
                    {c.field === "NET_AMT" && ""}
                  </td>
                ))}
            </tr>

            <tr>
              {columns
                .filter((c) => {
                  if (c.field === "ICODE") {
                    return true;
                  }
                  if (c.field === "TAX1_AMT") {
                    return true;
                  }
                  if (c.field === "VAT_AMOUNT") {
                    return true;
                  }
                  if (c.field === "HSN_CODE") {
                    return true;
                  }
                  if (c.field === "DISQ_RT") {
                    return true;
                  }
                  if (c.field === "NET_AMT") {
                    return true;
                  }
                  if (c.field === "CESS_AMT") {
                    return true;
                  }
                  return false;
                })
                .map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        c.field !== "NET_AMT"
                          ? "1px solid black"
                          : "0px solid black",
                      borderTop: "1px solid black",
                      padding: "5px",
                      textAlign:
                        c.field === "CESS_AMT" || c.field === "NET_AMT"
                          ? "right"
                          : "left",
                    }}
                    colSpan={
                      c.field === "ICODE"
                        ? 2
                        : c.field === "HSN_CODE"
                        ? 3
                        : c.field === "DISQ_RT"
                        ? 3
                        : c.field === "TAX1_AMT"
                        ? 3
                        : 1
                    }
                  >
                    {c.field === "ICODE" &&
                      `Add Charges : ${partyDetails.ADD_CHARGES}`}
                    {c.field === "HSN_CODE" &&
                      `Surcharge : ${surchargeTotal.toFixed(2)}`}
                    {c.field === "DISQ_RT" &&
                      `Cess : ${partyDetails.ADDITIONAL_CESS}`}
                    {c.field === "CESS_AMT" && "Total"}
                    {c.field === "TAX1_AMT" && ``}
                    {c.field === "NET_AMT" &&
                      rows
                        .reduce((acc, row) => acc + Number(row.AMOUNT), 0)
                        .toFixed(2)}
                  </td>
                ))}
            </tr>

            <tr>
              {columns
                .filter((c) => {
                  if (c.field === "ICODE") {
                    return true;
                  }
                  if (c.field === "TAX1_AMT") {
                    return true;
                  }
                  if (c.field === "VAT_AMOUNT") {
                    return true;
                  }
                  if (c.field === "HSN_CODE") {
                    return true;
                  }
                  if (c.field === "DISQ_RT") {
                    return true;
                  }
                  if (c.field === "NET_AMT") {
                    return true;
                  }
                  if (c.field === "CESS_AMT") {
                    return true;
                  }
                  return false;
                })
                .map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        c.field !== "NET_AMT"
                          ? "1px solid black"
                          : "0px solid black",
                      borderTop: "1px solid black",
                      padding: "5px",
                      textAlign:
                        c.field === "CESS_AMT" || c.field === "NET_AMT"
                          ? "right"
                          : "left",
                    }}
                    colSpan={
                      c.field === "ICODE"
                        ? 2
                        : c.field === "HSN_CODE"
                        ? 3
                        : c.field === "DISQ_RT"
                        ? 3
                        : c.field === "TAX1_AMT"
                        ? 3
                        : 1
                    }
                  >
                    {c.field === "ICODE" &&
                      `Less Charges : ${partyDetails.LESS_CHARGES}`}
                    {c.field === "HSN_CODE" && ``}
                    {c.field === "DISQ_RT" && ``}
                    {c.field === "CESS_AMT" && "Scheme"}
                    {c.field === "TAX1_AMT" && ``}
                    {c.field === "NET_AMT" && partyDetails.SCHEME_AMOUNT}
                  </td>
                ))}
            </tr>
            <tr>
              {columns
                .filter((c) => {
                  if (c.field === "ICODE") {
                    return true;
                  }
                  if (c.field === "NET_AMT") {
                    return true;
                  }

                  return false;
                })
                .map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        c.field !== "NET_AMT"
                          ? "1px solid black"
                          : "0px solid black",
                      borderTop: "1px solid black",
                      padding: "5px",
                      textAlign: "right",
                    }}
                    colSpan={
                      c.field === "ICODE"
                        ? 13
                        : c.field === "HSN_CODE"
                        ? 3
                        : c.field === "DISQ_RT"
                        ? 3
                        : c.field === "TAX1_AMT"
                        ? 3
                        : 1
                    }
                  >
                    {c.field === "ICODE" && `Discount`}
                    {c.field === "NET_AMT" &&
                      rows
                        .reduce((acc, row) => acc + Number(row.DISQ_AMT), 0)
                        .toFixed(2)}
                  </td>
                ))}
            </tr>

            <tr>
              {columns
                .filter((c) => {
                  if (c.field === "ICODE") {
                    return true;
                  }
                  if (c.field === "CESS_AMT") {
                    return true;
                  }
                  if (c.field === "NET_AMT") {
                    return true;
                  }

                  return false;
                })
                .map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        c.field === "CESS_AMT"
                          ? "1px solid black"
                          : "0px solid black",
                      borderTop: "1px solid black",
                      padding: "5px",
                      textAlign: "right",
                    }}
                    colSpan={
                      c.field === "ICODE"
                        ? 8
                        : c.field === "CESS_AMT"
                        ? 5
                        : c.field === "DISQ_RT"
                        ? 3
                        : c.field === "TAX1_AMT"
                        ? 3
                        : 1
                    }
                  >
                    {c.field === "ICODE" && ``}
                    {c.field === "CESS_AMT" && "Fright"}
                    {c.field === "NET_AMT" && partyDetails.FREIGHT}
                  </td>
                ))}
            </tr>

            <tr>
              {columns
                .filter((c) => {
                  if (c.field === "ICODE") {
                    return true;
                  }
                  if (c.field === "CESS_AMT") {
                    return true;
                  }
                  if (c.field === "NET_AMT") {
                    return true;
                  }

                  return false;
                })
                .map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        c.field === "CESS_AMT"
                          ? "1px solid black"
                          : "0px solid black",
                      padding: "5px",
                      textAlign: "right",
                    }}
                    colSpan={
                      c.field === "ICODE"
                        ? 8
                        : c.field === "CESS_AMT"
                        ? 5
                        : c.field === "DISQ_RT"
                        ? 3
                        : c.field === "TAX1_AMT"
                        ? 3
                        : 1
                    }
                  >
                    {c.field === "ICODE" && ``}
                    {c.field === "CESS_AMT" && "Round Off"}
                    {c.field === "NET_AMT" && roundOff.toFixed(2)}
                  </td>
                ))}
            </tr>
            <tr>
              {columns
                .filter((c) => {
                  if (c.field === "ICODE") {
                    return true;
                  }
                  if (c.field === "CESS_AMT") {
                    return true;
                  }
                  if (c.field === "NET_AMT") {
                    return true;
                  }

                  return false;
                })
                .map((c, i) => (
                  <td
                    style={{
                      borderRight:
                        c.field !== "NET_AMT"
                          ? "1px solid black"
                          : "0px solid black",
                      borderTop: "1px solid black",
                      padding: "5px",
                      textAlign: "right",
                      fontWeight: "600",
                      fontSize: "12px",
                      borderBottom: "1px solid black",
                      backgroundColor:
                        c.field === "NET_AMT" ? "#f0f2f5" : "white",
                    }}
                    colSpan={
                      c.field === "ICODE"
                        ? 8
                        : c.field === "CESS_AMT"
                        ? 5
                        : c.field === "DISQ_RT"
                        ? 3
                        : c.field === "TAX1_AMT"
                        ? 3
                        : 1
                    }
                  >
                    {c.field === "ICODE" && ``}
                    {c.field === "CESS_AMT" && "Net Amount Payable"}
                    {c.field === "NET_AMT" && FormatNumber2(grandTotal)}
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
        <div className="p-2 flex justify-center text-[13px] font-semibold ">
          Hsn Summary
        </div>

        <table className="min-w-full border  border-gray-300 text-left">
          <thead>
            <tr className="">
              <th className="border text-center px-4 py-2">HSN Code</th>
              <th className="border text-center px-4 py-2">Tax Rate (%)</th>
              <th className="border text-center px-4 py-2">Amount</th>
              <th className="border text-center px-4 py-2">Discount Amount</th>
              <th className="border  text-center px-4 py-2">Tax Amount</th>
              <th className="border text-center px-4 py-2">Total</th>
            </tr>
          </thead>
          <tbody>
            {hsnSummary.map((item, index) => (
              <tr key={index} className="border">
                <td className="border px-4 py-2">{item.HSN_CODE}</td>
                <td className="border px-4 py-2 text-right">
                  {item.TAX_RATE}%
                </td>
                <td className="border px-4 text-right py-2">
                  {FormatNumber2(item.AMOUNT)}
                </td>
                <td className="border px-4 text-right py-2">
                  {FormatNumber2(item.DISQ_AMT)}
                </td>
                <td className="border px-4 text-right py-2">
                  {FormatNumber2(item.TAX_AMT)}
                </td>
                <td className="border px-4 text-right py-2">
                  {FormatNumber2(item.TOTAL)}
                </td>
              </tr>
            ))}
            <tr className="border bg-[#f0f2f5]">
              <td className="border px-4 py-2">{""}</td>
              <td className="border px-4 py-2 text-right font-bold">
                {"Total"}
              </td>
              <td className="border px-4 text-right py-2">
                {FormatNumber2(
                  hsnSummary.reduce((acc, row) => acc + Number(row.AMOUNT), 0)
                )}
              </td>
              <td className="border px-4 text-right py-2">
                {FormatNumber2(
                  hsnSummary.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0)
                )}
              </td>
              <td className="border px-4 text-right py-2">
                {FormatNumber2(
                  hsnSummary.reduce((acc, row) => acc + Number(row.TAX_AMT), 0)
                )}
              </td>
              <td className="border px-4 text-right py-2">
                {FormatNumber2(
                  taxTotal +
                    (hsnSummary[0]?.ROUND_OFF ? hsnSummary[0]?.ROUND_OFF : 0)
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* Totals */}
        {/* <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <p>Taxable Amount: {totals.taxableAmount}</p>
          <p>CGST Total: {totals.cgst}</p>
          <p>SGST Total: {totals.sgst}</p>
          <p style={{ fontWeight: "bold" }}>Grand Total: {totals.grandTotal}</p>
        </div> */}
      </div>
    </div>
  );
};

export default InvoiceTemplate;
