import {
  FormatNumber,
  FormatNumber2,
} from "../../utility/functions/formatNumber";
import { HeaderWidth } from "../../utility/constant";

export const ItemwisePurchaseColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      headerAlign: "center",
      width: 60,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Code
        </div>
      ),
    },

    {
      field: "DEP_NAME",
      accessor: "DEP_NAME",
      type: "string",
      headerAlign: "center",

      width: width < 768 ? 100 : 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Department
        </div>
      ),
    },
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      headerAlign: "center",

      width: width < 768 ? 100 : 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Item Name
        </div>
      ),
    },

    {
      field: "IQNTY",
      accessor: "IQNTY",
      type: "string",
      width: 60,
      align: "right",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Issue
        </div>
      ),
    },
    {
      field: "RQNTY",
      accessor: "RQNTY",
      type: "string",
      width: 60,
      align: "right",

      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Return
        </div>
      ),
    },
    {
      field: "RATE",
      accessor: "RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Rate
        </div>
      ),
    },

    {
      field: "IAMOUNT",
      accessor: "IAMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Issue Amount
        </div>
      ),
    },

    {
      field: "RAMOUNT",
      accessor: "RAMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Return Total
        </div>
      ),
    },
  ];

  return columns;
};

export const SectionWisePurchaseColumns = (width) => {
  const columns = [
    {
      field: "DEP_NAME",
      accessor: "DEP_NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1281 ? 100 : 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Department
        </div>
      ),
    },

    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : width < 1281 ? 100 : 150,
      align: "left",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Amount
        </div>
      ),
    },
  ];
  return columns;
};
