import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyAddress } from "../../Redux/profile";
import "./print.css";
import moment from "moment";
const IssuePrint = ({ data, rows, partyDetails }) => {
  const { sellerInfo, invoiceDetails, columns } = data;
  const companyDetails = useSelector(selectCompanyAddress);

  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid black",
        fontFamily: "Arial, sans-serif",
        fontSize: "12px",
      }}
    >
      <div id="print-component">
        {/* Header Section */}
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>
            {companyDetails?.companyName}
          </div>
          <div style={{ fontWeight: "500", fontSize: "14px" }}>
            {companyDetails?.address +
              "," +
              companyDetails?.city +
              "," +
              companyDetails?.mobile}
          </div>
          <p style={{ fontSize: "14px", fontWeight: "600" }}>Issue Challan </p>
        </div>

        {/* Seller Information */}
        <div style={{ marginBottom: "10px" }}>
          <div className="flex justify-between mb-1 border-b  pb-1">
            <div className="flex" style={{}}>
              <div style={{ width: "170px", fontWeight: "bold" }}>
                {" "}
                Issued Challan No
              </div>{" "}
              :<div className="ml-1">{partyDetails.SERIAL_NO}</div>
            </div>

            <div className="flex">
              {" "}
              <div className="mr-1" style={{ fontWeight: "bold" }}>
                Date :
              </div>
              {moment(partyDetails.DATE).format("YYYY-MM-DD")}
            </div>
          </div>

          <div className="flex justify-between">
            <div className="b flex" style={{}}>
              <div style={{ width: "170px", fontWeight: "bold" }}>
                Department / Outlet Name{" "}
              </div>{" "}
              :<div className="ml-1 ">{partyDetails.DEP_NAME}</div>
            </div>

            <div className="flex">
              {" "}
              <div className="mr-1" style={{ fontWeight: "bold" }}>
                Indent No. :
              </div>
              <div className="min-w-[50px]">{partyDetails.INDENT_NO}</div>
            </div>
          </div>
        </div>

        {/* Itemized Table */}
        <table
          style={{
            border: "1px solid black",
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              {columns.map((c) => (
                <th
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                  key={c.field}
                >
                  {c.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((r, index) => (
              <tr key={index}>
                {columns.map((c) => (
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      textAlign: c.align ? c.align : "left",
                    }}
                    key={c.field}
                  >
                    {r[c.field]}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              {columns.map((c) => (
                <td
                  style={{
                    border: "1px solid black",
                    padding: "5px",
                    fontWeight: "600",
                    textAlign: c.align ? c.align : "left",
                  }}
                  key={c.field}
                >
                  {c.field === "RATE" && "Total Qnty"}
                  {c.field === "IQNTY" &&
                    rows.reduce((acc, row) => acc + Number(row.IQNTY), 0)}
                  {c.field === "RQNTY" && "Total..."}
                  {c.field === "AMOUNT" &&
                    rows.reduce((acc, row) => acc + Number(row.TOTAL), 0)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="flex justify-between">
          <div className="b flex" style={{}}>
            <div style={{ fontWeight: "bold" }}>Issued By :</div>
            <div className="ml-1 ">{partyDetails.ISSUED_BY}</div>
          </div>

          <div className="flex">
            {" "}
            <div className="mr-1" style={{ fontWeight: "bold" }}>
              Issued To :
            </div>
            <div className="min-w-[50px]">{partyDetails.ISSUED_TO}</div>
          </div>
        </div>

        {/* Totals */}
        {/* <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <p>Taxable Amount: {totals.taxableAmount}</p>
          <p>CGST Total: {totals.cgst}</p>
          <p>SGST Total: {totals.sgst}</p>
          <p style={{ fontWeight: "bold" }}>Grand Total: {totals.grandTotal}</p>
        </div> */}
      </div>
    </div>
  );
};

export default IssuePrint;
