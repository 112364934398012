import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import checkLastPurchasePrice from "./common";
import FirstIcodeItem from "./icodeEnterValue";
import uniqeid from "uniqid";
import moment from "moment";
const getCompanyDetails = () => {
  const data = localStorage.getItem("companyDetails");
  if (data) {
    return JSON.parse(data);
  } else {
    return "";
  }
};
export const emptyRowDetails = {
  id: uniqeid(),
  SERIAL_NO: "",
  DATE: moment().format("YYYY-MM-DD"),
  ENT_TIME: moment().format("HH:MM:SS"),
  INDENT_NO: "",
  ISSUED_TO: "",
  ISSUED_BY: "",
  DEP_NAME: "",
  ICODE: "",
  ALIAS_CODE: "",
  ITEMNAME: "",
  PACKING: "",
  UNIT: "",
  SECTION_NAME: "",
  SUB_SEC_NAME: "",
  TAX1_RATE: 0,
  TAX2_RATE: 0,
  TAX3_RATE: 0,
  IQNTY: 1,
  RQNTY: 0,
  STOCK_AVL: 0,
  ITEM_DESC: "",
  AMOUNT: 0,
  TOTAL: 0,
  RTOTAL: 0,
  RATE: 0,
};
export function enterRowWithItemDetails(
  filteredItems,
  rows,
  id,
  dispatch,
  setRows,
  value,
  setAlertTwoButtonModal,
  setItemMasterStatus
) {
  if (filteredItems[0]) {
    const isSameIdExist = rows.filter((row) => row.id === id);
    if (filteredItems[0].BAL_STOCK === 0) {
      dispatch(
        setAlertWithTitle({
          title: `Stock available: ${filteredItems[0].BAL_STOCK} `,
          msg: `Low Stock balance`,
        })
      );
    }

    if (isSameIdExist[0]) {
      setRows((old) => {
        const list = old.map((item) => {
          if (item.id === id) {
            const qnty = 1;

            const i = FirstIcodeItem(
              id,
              qnty,
              filteredItems[0],
              filteredItems[0].RQNTY ? filteredItems[0].RQNTY : 0
            );

            return i;
          } else {
            return item;
          }
        });
        const isEmptyRow = list.filter((r) => r.ITEMNAME === "");
        if (isEmptyRow[0]) {
          return [...list];
        } else {
          return [
            ...list,
            {
              ...emptyRowDetails,
              id: uniqeid(),
            },
          ];
        }
      });
    } else {
      setRows((old) => {
        const qnty = 1;
        console.log(filteredItems);

        const i = FirstIcodeItem(uniqeid(), qnty, filteredItems[0], 0);
        return [
          ...old,
          {
            ...i,
          },
          {
            ...emptyRowDetails,
            id: uniqeid(),
          },
        ];
      });
    }
    dispatch(setSpinnerLoading(false));
    // }
  } else {
    setAlertTwoButtonModal({
      status: true,
      title: "Confirmation",
      msg: "Item Not Found . Do you want to create the Item?",
      button1: "Yes",
      button1Click: () => {
        dispatch(setSpinnerLoading(false));
        // const nearestInput = document.getElementById(
        //   id + "-" +'ICODE'
        // );
        // if (nearestInput) {
        //   nearestInput.focus();
        //   nearestInput.select();
        // }
        setItemMasterStatus(true);
        setAlertTwoButtonModal({ status: false });
      },
      button2: "No",
      button2Click: () => {
        const nearestInput = document.getElementById(id + "-" + "ICODE");
        if (nearestInput) {
          nearestInput.focus();
          nearestInput.select();
        }
        dispatch(setSpinnerLoading(false));
        setAlertTwoButtonModal({ status: false });
      },
    });
  }
}
