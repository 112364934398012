import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import "../../utility/component/datagrid/print.css";
import Printcomp from "./printComp";
import { selectCompanyAddress } from "../../utility/Redux/profile";
import { useSelector } from "react-redux";
const GeniusTableForReports = ({
  fromDate,
  ToDate,
  columns,
  data,
  title,
  Fotter,
  headerWidth,
  customeHeader,
  filteredData,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const companyName = localStorage.getItem("company");
  const [finalFilterData, setFinalFilterData] = useState([]);
  const [TotalFilterData, setTotalFilterData] = useState([]);
  const totalAmt = data.reduce((acc, row) => acc + Number(row.AMOUNT), 0);
  console.log(filteredData);
  useEffect(() => {
    setTotalFilterData(
      rows[0]
        ? filteredData
          ? [
              ...rows,
              {
                ...rows[0],
                values: {
                  ...rows[0].values,
                  ITEMNAME: "Total...",
                  SECTION_NAME: "",
                  ICODE: "",
                  RATE: "",
                  IQNTY: data.reduce((acc, row) => acc + Number(row.IQNTY), 0),
                  RQNTY: data.reduce((acc, row) => acc + Number(row.RQNTY), 0),
                  IAMOUNT: data
                    .reduce((acc, row) => acc + Number(row.IAMOUNT), 0)
                    .toFixed(2),
                  RAMOUNT: data
                    .reduce((acc, row) => acc + Number(row.RAMOUNT), 0)
                    .toFixed(2),
                },
              },
            ]
          : []
        : []
    );
  }, [rows, finalFilterData, filteredData]);

  useEffect(() => {
    let listtt = filteredData
      ? filteredData[0]
        ? [
            ...filteredData,
            {
              SECTION_NAME: "TOTAL..",
            },
          ]
        : []
      : [];
    setFinalFilterData(listtt);
  }, [filteredData]);
  const companyDetails = useSelector(selectCompanyAddress);

  return (
    <div
      id="main"
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      <div
        className={`${headerWidth ? `pt-10` : "pt-10"} px-4`}
        style={
          window.innerWidth < 600
            ? {
                width: "100%",
                height: "auto",
                overflow: "hidden",
              }
            : {
                width: "100%",
                height: "auto",
                overflow: "hidden",
              }
        }
      >
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>
            {companyDetails?.companyName}
          </div>
          <div style={{ fontWeight: "500", fontSize: "14px" }}>
            {companyDetails?.address +
              "," +
              companyDetails?.city +
              "," +
              companyDetails?.mobile}
          </div>
          <p style={{ fontSize: "14px", fontWeight: "600" }}>
            Item Wise Issue ( {`${fromDate} To  ${ToDate}`} )
          </p>
        </div>
        <table
          {...getTableProps()}
          style={{ border: "1px solid black" }}
          className="w-full"
        >
          <thead>
            {headerGroups.map((headerGroup, key) => (
              <tr
                key={key}
                style={{ fontSize: "12px" }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => (
                  <td
                    {...column.getHeaderProps()}
                    className=" text-center py-2  overflow-hidden"
                    style={
                      columns[index].width
                        ? {
                            width: columns[index].width,
                            fontWeight: 500,
                            borderRight: "1px solid black",
                            borderBottom: "1px solid black",
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight: "1px solid black",
                            borderBottom: "1px solid black",
                          }
                    }
                    key={index}
                  >
                    {columns[index].renderHeader().props.children}
                  </td>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              border: "1px solid black",
            }}
            className=""
            {...getTableBodyProps()}
          >
            {!filteredData ? (
              rows.map((row, key) => {
                prepareRow(row);

                return (
                  <tr key={key} {...row.getRowProps()}>
                    {row.cells.map((cell, k) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "8px",
                          borderRight: "1px dashed black",
                          borderTop: "1px dashed black",
                          fontSize: "11px",
                        }}
                        key={k}
                      >
                        <div
                          style={{
                            textAlign: cell.render("Cell").props.cell.column
                              .align
                              ? cell.render("Cell").props.cell.column.align
                              : "left",
                          }}
                          className="whitespace-nowrap"
                        >
                          {cell.render("Cell").props.cell.column.type === "date"
                            ? moment(
                                cell.render("Cell").props.cell.value
                              ).format("YYYY-MM-DD")
                            : cell.render("Cell").props.cell.value === "0.00" ||
                              cell.render("Cell").props.cell.value === 0
                            ? null
                            : cell.render("Cell")}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <Printcomp
                finalFilterData={finalFilterData}
                TotalFilterData={TotalFilterData}
                prepareRow={prepareRow}
              />
            )}
          </tbody>
        </table>
        <div
          style={{
            width: columns.reduce((acc, row) => acc + Number(row.width), 0),
          }}
        >
          {Fotter ? Fotter : ""}
        </div>

        <div className="w-full flex justify-between">
          <div
            style={{
              fontSize: "12px",
            }}
            className="flex  pt-2"
          >
            <span style={{ fontSize: "10px", color: "#3b3a39" }}>
              {" "}
              Powered by{" "}
            </span>{" "}
            <span
              style={{ fontSize: "11px", color: "#171716" }}
              className="pl-1"
            >
              geniusoffice{" "}
            </span>
          </div>
          <div className="flex pt-2 items-center ">
            <div
              style={{
                fontSize: "11px",
                color: "#3b3a39",
                fontWeight: "600px",
              }}
            >
              Date :{" "}
            </div>

            <div
              style={{
                fontSize: "10px",
                color: "#3b3a39",
                fontWeight: "500px",
              }}
              className="pl-1"
            >
              {moment().format("YYYY-MM-DD HH:mm ")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeniusTableForReports;
