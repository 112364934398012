import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IssueColumns } from "./columns";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import {
  LocalButton,
  ModifyButton,
  PrintButton,
  UndoButton,
} from "../utility/component/buttons";
import EditableDataGrid from "../utility/component/datagrid/editableDatagrid";
import {
  setAlertWithTitle,
  setIssueStockItem,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from "../utility/Redux/modal";
import uniqeId from "uniqid";
import ModNumericKeyboard from "../utility/component/modalNumeric";
import { ItemMasterHelp } from "../help";
import FirstIcodeItem from "./icodeEnterValue";

import { selectToken } from "../utility/Redux/security";
import BarcodeScanner from "../utility/component/barcodeScanner";
import IsCameraAvailval from "../utility/functions/isCameraAvailvl";
import { getItemByAlios, getItemByCode } from "../utility/constant";
import { emptyRowDetails, enterRowWithItemDetails } from "./fun";
import PurchasePDFDetails from "../test";
import OutlinedTextBox from "../utility/component/textInput";
const Datagrid = ({
  savePurchaseBill,
  setItemMasterStatus,
  handlePrint,
  setIsClearForm,
  delayedFunction,
  partyDetails,
  rows,
  setRows,
  alertTwoButtonModal,
  handlePrint2,
  clearState,
  setAccountMasterHelpStatus,
  inputRef,
  setAlertTwoButtonModal,
}) => {
  const width = useSelector(selectWidth);
  const columns = IssueColumns(width);
  const [searchText, setSearchText] = useState("");
  const [itemMasterHelpStatus, setItemMasterHelpStatus] = useState(false);
  const [scannerItem, setScannerItem] = useState("");
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [rowId, setRowId] = useState("");
  const dispatch = useDispatch();
  const [isDeviceHaveCamera, setIsDeviceHaveCamera] = useState(false);
  const [scannerStatus, setScannerStatus] = useState(false);
  const [file, setFile] = useState("");
  const [pdfModalStatus, setPdfmodalStatus] = useState(false);
  const iTotal = rows.reduce((acc, row) => acc + Number(row.AMOUNT), 0);
  const rTotal = rows.reduce((acc, row) => acc + Number(row.RAMOUNT), 0);
  const iQnty_Total = rows.reduce((acc, row) => acc + Number(row.IQNTY), 0);
  const rQnty_Total = rows.reduce((acc, row) => acc + Number(row.RQNTY), 0);
  const keyDown = (e, currentFieldName, id) => {
    const value = e.target.value;

    if (currentFieldName === "ICODE" && e.code === "F4") {
      if (partyDetails.DEP_NAME) {
        setRowId(id);
        setItemMasterHelpStatus(true);
      } else {
        setAlertTwoButtonModal({
          status: true,
          title: "Alert",
          msg: "Please Select department First.",
          button1: "ok",

          button1Click: () => {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: false,
            });
            setAccountMasterHelpStatus(true);
          },
        });
      }
    } else {
      if (
        (currentFieldName === "ICODE" &&
          e.code === "Enter" &&
          value !== "" &&
          value !== "0") ||
        (e.code === "NumpadEnter" &&
          value !== "" &&
          value !== "0" &&
          currentFieldName === "ICODE")
      ) {
        if (partyDetails.DEP_NAME) {
          if (!isNaN(value)) {
            const v = value.toString().replace(" ", "");
            if (v) {
              const isFilled = rows.find((r) => r.id === id);
              if (!isFilled?.ITEMNAME) {
                if (
                  !rows.find((o) => {
                    if (o.ITEMNAME) {
                      if (Number(o.ICODE) === Number(value) && o.id !== id) {
                        return o; // Return the object if condition matches
                      }
                      if (
                        o?.ALIAS_CODE?.toString() === value.toString() &&
                        o.id !== id
                      ) {
                        return o; // Return the object if condition matches
                      }
                    }
                    return false;
                  })
                ) {
                  if (
                    e.code === "Enter" ||
                    e.code === "NumpadEnter" ||
                    e.code === "ArrowRight"
                  ) {
                    const currentFieldIndex = columns.findIndex(
                      (column) => column.field === currentFieldName
                    );
                    const nextEditableField = columns.find(
                      (column, index) =>
                        index > currentFieldIndex && column.editable
                    );
                    if (nextEditableField) {
                      const nearestInput = document.getElementById(
                        id + "-" + nextEditableField.field
                      );
                      setTimeout(() => {
                        if (nearestInput) {
                          nearestInput.focus();
                          nearestInput.select();
                        }
                      }, 2000);
                    }
                  }
                  getItemByCode(prefix, value, token, dispatch, true).then(
                    (codeItem) => {
                      if (!codeItem[0]) {
                        getItemByAlios(
                          prefix,
                          value,
                          token,
                          dispatch,
                          true
                        ).then((aliosItem) => {
                          enterRowWithItemDetails(
                            aliosItem,
                            rows,
                            id,
                            dispatch,
                            setRows,
                            value,
                            setAlertTwoButtonModal,
                            setItemMasterStatus
                          );
                        });
                      } else {
                        enterRowWithItemDetails(
                          codeItem,
                          rows,
                          id,
                          dispatch,
                          setRows,
                          value,
                          setAlertTwoButtonModal,
                          setItemMasterStatus
                        );
                      }
                    }
                  );
                } else {
                  setAlertTwoButtonModal((o) => {
                    return {
                      ...o,
                      status: true,
                      title: "Alert",
                      msg: "Item already exist",
                      button1: "ok",
                      button1Click: () => {
                        setAlertTwoButtonModal((o) => {
                          return {
                            ...o,
                            status: false,
                          };
                        });
                      },
                    };
                  });
                }
              } else {
                const currentFieldIndex = columns.findIndex(
                  (column) => column.field === currentFieldName
                );
                const nextEditableField = columns.find(
                  (column, index) =>
                    index > currentFieldIndex && column.editable
                );
                if (nextEditableField) {
                  const nearestInput = document.getElementById(
                    id + "-" + nextEditableField.field
                  );
                  if (nearestInput) {
                    nearestInput.focus();
                    nearestInput.select();
                  }
                }
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Please enter Icode to continue",
                })
              );
            }
          } else {
            setRows((r) =>
              r.map((o) => {
                if (o.id === id) {
                  return {
                    ...o,
                    ICODE: "",
                  };
                } else {
                  return o;
                }
              })
            );
            setSearchText(value);
            setRowId(id);
            setItemMasterHelpStatus(true);
          }
        } else {
          setAlertTwoButtonModal({
            status: true,
            title: "Alert",
            msg: "Please Select department First.",
            button1: "ok",

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              setAccountMasterHelpStatus(true);
            },
          });
        }
      } else {
        if (currentFieldName === "ICODE") {
          setAlertTwoButtonModal({
            status: true,
            title: "Alert",
            msg: "Please Enter Item Code First.",
            button1: "ok",

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
            },
          });
        } else {
          if (
            e.code === "Enter" ||
            e.code === "NumpadEnter" ||
            e.code === "ArrowRight"
          ) {
            const currentFieldIndex = columns.findIndex(
              (column) => column.field === currentFieldName
            );
            const nextEditableField = columns.find(
              (column, index) => index > currentFieldIndex && column.editable
            );
            if (nextEditableField) {
              const nearestInput = document.getElementById(
                id + "-" + nextEditableField.field
              );
              if (nearestInput) {
                nearestInput.focus();
                nearestInput.select();
              }
            }
          }
        }
      }
    }
  };

  const nextRow = (index) => {
    console.log(rows);
    delayedFunction(rows[index].id);
  };

  const deletRow = (id) => {
    setRows((o) => {
      const list = o.filter((r) => r.id !== id);
      if (list.find((o) => o.ITEMNAME === "")) {
        return list;
      } else {
        return [
          ...list,
          {
            ...emptyRowDetails,
            id: uniqeId(),
          },
        ];
      }
    });
  };
  console.log(rows);
  const CellEdit = ({ id, field, value }) => {
    if (field === "ICODE") {
      const isSameIdExist = rows.filter((row) => row.id === id);
      if (isSameIdExist[0]) {
        setRows((old) =>
          old.map((item) => {
            if (item.id === id && !item.ITEMNAME) {
              return {
                ...item,
                ICODE: value,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        setRows((old) => {
          return [
            ...old,
            {
              ...old[0],
            },
          ];
        });
      }
    }

    if (field === "IQNTY" || field === "RQNTY") {
      console.log(value);
      const updatedRows = rows.map((row) => {
        if (row.id === id) {
          const qnty =
            field === "IQNTY"
              ? value.includes("-")
                ? -value.replace("-", "")
                : value
              : row.IQNTY;
          const rqnty =
            field === "RQNTY"
              ? value.includes("-")
                ? -value.replace("-", "")
                : value
              : row.RQNTY;

          const item = rows.filter((i) => i.id === id);
          if (field === "IQNTY" && item[0].STOCK_AVL < qnty) {
            dispatch(
              setAlertWithTitle({
                title: `Stock available: ${item[0].STOCK_AVL} `,
                msg: `Low Stock balance`,
              })
            );
          }

          const i = FirstIcodeItem(id, qnty, item[0], rqnty);
          console.log(i);
          return i;
        }
        return row;
      });

      setRows(updatedRows);
    }
  };

  useEffect(() => {
    IsCameraAvailval()
      .then(() => {
        setIsDeviceHaveCamera(true);
      })
      .catch(() => {
        setIsDeviceHaveCamera(false);
      });
  }, []);

  const windowWidth = useSelector(selectWidth);

  return (
    <div
      style={{
        height: `calc(100% - ${windowWidth > 1440 ? "90px" : "90px"})`,
      }}
      className="  w-auto "
    >
      <div
        style={{
          height: `calc(100% - ${
            windowWidth < 1280
              ? "100px"
              : windowWidth < 1536
              ? "100px"
              : windowWidth < 1800
              ? "100px"
              : "100px"
          })`,
        }}
        className=" min-h-[200px]"
      >
        <EditableDataGrid
          nextRow={nextRow}
          keyDown={keyDown}
          ignoreMinWidth={true}
          columns={columns}
          data={rows}
          onScannerClick={
            isDeviceHaveCamera
              ? (e) => {
                  setScannerItem(e);
                  setScannerStatus(true);
                }
              : false
          }
          deletRow={deletRow}
          onEditCell={CellEdit}
          firstInputRef={inputRef}
          onCellDoubleClick={(e) => {
            if (e.column.editable && e.column.field !== "EXP_DATE") {
              if (true) {
                dispatch(setnumericKeyboardType(e.column.field));
                dispatch(setnumericKeyboardStatus(true));
                dispatch(
                  setIssueStockItem({
                    title: e.column.field,

                    id: e.row.original.id,
                    value: e.value,
                  })
                );
              } else {
              }
            }
          }}
        />
      </div>

      <div className="flex  mt-[10px]">
        <div
          style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
          className="p-3 bg-white w-full flex "
        >
          <div className="">
            <div
              className={
                "mt-3 grid grid-cols-3 l12:grid-cols-[1fr,1fr,1fr,1fr,350px] gap-3"
              }
            >
              <OutlinedTextBox
                type={"number"}
                value={iQnty_Total}
                label={"Total Issue Qnty"}
              />

              <OutlinedTextBox
                type={"number"}
                value={rQnty_Total}
                label={"Total Return Qnty"}
              />

              <OutlinedTextBox
                type={"number"}
                value={iTotal}
                label={"Total Issue Amount"}
              />

              <OutlinedTextBox
                type={"number"}
                value={rTotal}
                label={"Total Return Amount"}
              />

              <div className=" flex justify-end ">
                {partyDetails.SERIAL_NO ? (
                  <ModifyButton onClick={savePurchaseBill} />
                ) : (
                  <LocalButton
                    title={"Save"}
                    bg={"#a8cf45"}
                    width={"300px"}
                    color={"black"}
                    onClick={savePurchaseBill}
                  />
                )}

                <UndoButton onClick={clearState} />
                <div className="ml-2">
                  <PrintButton
                    onClick={() => {
                      if (rows.find((o) => o.ICODE && o.ITEMNAME)) {
                        setIsClearForm(false);
                        handlePrint2();
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "Nothing found to print",
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ItemMasterHelp
        searchText={searchText}
        status={itemMasterHelpStatus}
        onClose={() => {
          setSearchText("");

          setItemMasterHelpStatus(false);
        }}
        onRowClick={(row) => {
          setSearchText("");
          setItemMasterHelpStatus(false);
          const d = {
            id: rowId,
            code: "Enter",
            target: {
              value: row.row.ICODE,
            },
          };
          keyDown(d, "ICODE", d.id);
        }}
      />
      <ModNumericKeyboard
        onChange={(e) => {
          if (e.field === "ICODE") {
            const d = {
              id: e.id,
              code: "Enter",
              target: {
                value: e.value,
              },
            };

            keyDown(d, "ICODE", e.id);
          } else {
            CellEdit(e);
          }
        }}
        editType={""}
        mode={"dark"}
      />
      <BarcodeScanner
        setStatus={setScannerStatus}
        status={scannerStatus}
        onChange={(o) => {
          keyDown(
            { target: { value: o }, code: "Enter" },
            "ICODE",
            scannerItem
          );
        }}
      />
      <PurchasePDFDetails
        onClose={() => {
          setPdfmodalStatus(false);
          setFile("");
        }}
        status={pdfModalStatus}
        file={file}
      />
    </div>
  );
};

export default Datagrid;
