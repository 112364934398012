import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const PurchaseSummaryColumns = (width) => {
  const columns = [
    {
      field: "SERIAL_NO",
      accessor: "SERIAL_NO",
      type: "string",
      headerAlign: "center",
      width: width < 768 ? 150 : width < 1400 ? 80 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Serial No.
        </div>
      ),
    },

    {
      field: "DATE",
      accessor: "DATE",
      type: "date",
      headerAlign: "center",

      width: width < 768 ? 100 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value ? moment(params.value).format("DD-MM-yyyy") : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Date
        </div>
      ),
    },
    {
      field: "INDENT_NO",
      accessor: "INDENT_NO",
      type: "string",
      headerAlign: "center",

      width: width < 768 ? 100 : width < 1400 ? 180 : 130,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value ? params.value : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Indent No.
        </div>
      ),
    },

    {
      field: "DEP_NAME",
      accessor: "DEP_NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Department Name
        </div>
      ),
    },
    {
      field: "ISSUED_BY",
      accessor: "ISSUED_BY",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Issued By
        </div>
      ),
    },
    {
      field: "ISSUED_TO",
      accessor: "ISSUED_TO",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Issued To
        </div>
      ),
    },
    {
      field: "TOTAL",
      accessor: "TOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 120,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Total
        </div>
      ),
    },
    {
      field: "RTOTAL",
      accessor: "RTOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 120,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Return Total
        </div>
      ),
    },
  ];

  return columns;
};
