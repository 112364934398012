import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../../utility/Redux/profile";
import { FromToDate } from "../../utility/component/fromToDate";
import CustomerMasterHelp from "../../help/customermasterHelp";
import { GetPurchaseSummary } from "./func";
import { selectToken } from "../../utility/Redux/security";
import AccountMasterHelp from "../../help/listAccountMasterHelp";
import { PurchaseSummaryColumns } from "./columns";
import Footer from "./footer";
import { useReactToPrint } from "react-to-print";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import PrintWithFooter from "../../utility/component/datagrid/printReportWithFooter";
import { PrintPurchaseSummaryColumns } from "./printColumns";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import { DepartmentMasterHelp } from "../../help";

const IssueSummary = () => {
  const prefix = useSelector(selectPrefix);
  const [purchaseList, setPurchaseList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const [getCustomerData, setCustomerData] = useState({
    table_prefix: prefix,
    FDATE: moment(fromDate).format("YYYY-MM-DD"),
    TDATE: moment(ToDate).format("YYYY-MM-DD"),
    DEP_NAME: "",
    MOBILE: "",
  });
  const columns = PurchaseSummaryColumns(width);
  const printColumns = PrintPurchaseSummaryColumns(width);
  const [customerHelpStatus, setCustomerHelpStatus] = useState(false);
  const [partyHelpStatus, setPartyHelpStatus] = useState(false);
  const printRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    GetPurchaseSummary(
      {
        table_prefix: prefix,
        FDATE: moment(fromDate).format("YYYY-MM-DD"),
        TDATE: moment(ToDate).format("YYYY-MM-DD"),
        DEP_NAME: getCustomerData.DEP_NAME ? getCustomerData.DEP_NAME : "",
      },
      token,
      dispatch,
      setPurchaseList
    );
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    const callback = (details, data) => {
      setCustomerData(details[0]);
      setPurchaseList(data);
      dispatch(setreportsFromDate(details[0].FDATE));
      dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("issue_summary", callback, elseFunc);
  }, []);

  return (
    <div className=" bg-white flex flex-col h-full w-full overflow-y-scroll">
      <div className=" flex flex-col h-full p-3  w-full">
        <div>
          {window.innerWidth <= 1000 ? (
            <>
              <FromToDateMobile
                departmentData={{
                  DEP_NAME: getCustomerData.DEP_NAME,
                }}
                onPrintClick={handlePrint}
                submit={okButton}
                onDepartmentClick={(e) => setPartyHelpStatus(true)}
                departmentClear={() => {
                  setCustomerData((e) => {
                    return {
                      ...e,
                      table_prefix: prefix,
                      FDATE: moment(fromDate).format("YYYY-MM-DD"),
                      TDATE: moment(ToDate).format("YYYY-MM-DD"),
                      DEP_NAME: "",
                    };
                  });
                  setPurchaseList([]);
                }}
              />
            </>
          ) : (
            <FromToDate
              onPrintClick={handlePrint}
              departmentData={{
                DEP_NAME: getCustomerData.DEP_NAME,
              }}
              hideReset
              submit={okButton}
              onDepartmentClick={(e) => setPartyHelpStatus(true)}
              departmentClear={() => {
                setCustomerData((e) => {
                  return {
                    ...e,
                    table_prefix: prefix,
                    FDATE: moment(fromDate).format("YYYY-MM-DD"),
                    TDATE: moment(ToDate).format("YYYY-MM-DD"),
                    DEP_NAME: "",
                  };
                });
                setPurchaseList([]);
              }}
            />
          )}
        </div>

        <div
          style={{ height: `calc(100% - 0px)`, width: "100%", marginTop: 6 }}
          className=" mb-2 "
        >
          {purchaseList[0] ? (
            <GeniusTableReports
              columns={columns}
              data={purchaseList}
              footer={<Footer columns={columns} rows={purchaseList} />}
            />
          ) : (
            <div className="h-full flex justify-center items-center text-sm text-gray-400 border mt-3 rounded-sm">
              No Records Found
            </div>
          )}
        </div>

        <div>
          <div
            style={{
              display: "none",
            }}
          >
            <div ref={printRef}>
              <PrintWithFooter
                customeHeader={
                  <div className="items-center flex justify-center w-full">
                    <div className="text-center "></div>
                    <div className="flex items-center">
                      <div className="mr-1 text-sm font-semibold flex justify-center "></div>
                      <div className="text-sm font-semibold">From :</div>
                      <div className="ml-1 text-sm">
                        {moment(fromDate).format("DD-MM-YYYY")}{" "}
                      </div>
                      <div className="text-sm font-semibold ml-2">To :</div>
                      <div className="ml-1 text-sm">
                        {moment(ToDate).format("DD-MM-YYYY")}{" "}
                      </div>
                    </div>
                  </div>
                }
                title={"Issue Summary"}
                marginTop={"120px"}
                headerWidth={true}
                data={purchaseList}
                columns={printColumns}
                footer={<Footer columns={printColumns} rows={purchaseList} />}
              />
            </div>
          </div>
        </div>
      </div>
      <DepartmentMasterHelp
        onRowClick={(e) => {
          setCustomerData((c) => {
            return {
              ...c,
              DEP_NAME: e.row.DEP_NAME,
            };
          });
          setPartyHelpStatus(false);
        }}
        status={partyHelpStatus}
        onClose={() => {
          setPartyHelpStatus(false);
        }}
      />
    </div>
  );
};

export default IssueSummary;
