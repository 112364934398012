import React, { useEffect, useRef, useState } from "react";
import PartyDetails from "./partyDetails";
import Datagrid from "./datagrid";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import { useFocus } from "../utility/hooks/hooks";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import moment from "moment";
import CallAPI from "../utility/functions/getData";
import uniqueId from "uniqid";
import { useReactToPrint } from "react-to-print";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ItemMasterModal from "../masters/item-master/itemModal";
import PartyMasterCreaterModal from "../help/partyMasterCreaterModal";
import dataToLocal from "../utility/component/dataToLocal";
import deleteWholeDatabase from "../utility/functions/deleteWholeDatabase";
import IsLocalData from "../utility/component/isLocalData";
import BarcodeList from "./barCodeList";
import { CallAPIPromise } from "../billing/common";
import { PrintColumns } from "./printColumns";
import { emptyRowDetails } from "./fun";
import IssuePrint from "../utility/component/datagrid/issuePrint";

const Issue = () => {
  const [itemMasterStatus, setItemMasterStatus] = useState(false);
  const muiTextBox = true;
  const [isClearForm, setIsClearForm] = useState(false);
  const [purchaseBillList, setPurcahseBillList] = useState([]);
  const [inputRef, setInputRefFoucs] = useFocus();
  const [partyNameRef, setPartyNameFocus] = useFocus();
  const [issueToRef, setIssueToRef] = useFocus();
  const [issuedByRef, setIssuedByRef] = useFocus();
  const [frieghtRef, setFrieghtFocus] = useFocus();
  const [barCodeListStatus, setBarCodeListStatus] = useState(false);
  const [barCodeList, setBarCodeList] = useState([]);
  const [partyMasterCreaterModalStatus, setPartyMasterCreaterModalStatus] =
    useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const handleClose = () => {
    setItemMasterStatus(false);
  };
  const printBarRef = useRef();
  const BillModeOption = [
    {
      name: "Bill",
      value: "Bill",
    },
    { name: "Cash", value: "Cash" },
  ];
  const TaxTypeOption = [
    {
      name: "EXCLUSIVE",
      value: "EXCLUSIVE",
    },
    { name: "INCLUSIVE", value: "INCLUSIVE" },
  ];

  const BillTypeOption = [
    {
      name: "Retail Purchase",
      value: "Retail Purchase",
    },
    { name: "GST Purchase", value: "GST Purchase" },
  ];
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const defaultPartyDetails = {
    SERIAL_NO: "",
    DATE: moment().format("YYYY-MM-DD"),
    ENT_TIME: moment().format("HH:MM:SS"),
    INDENT_NO: "",
    ISSUED_TO: "",
    ISSUED_BY: "",
    DEP_NAME: "",
  };
  const [partyDetails, setPartyDetails] = useState(defaultPartyDetails);

  const [purchaseRef, setPurchaseBillNoFocus] = useFocus();
  const [purchaseAmtRef, setPurcahseAmtFocus] = useFocus();
  const [partyRef, setPartyFocus] = useFocus();
  const [currentINVNO, setCurrentINVNO] = useState("");
  const [rows, setRows] = useState([
    {
      ...emptyRowDetails,
      id: uniqueId(),
    },
  ]);
  const [accountMasterHelpStatus, setAccountMasterHelpStatus] = useState(false);
  const currentDate = moment().format("YYYY-MM-DD");

  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const [duplicatePurchaseBill, setDuplicatePurcahseBill] = useState(false);

  const savePurchaseBill = () => {
    const iTotal = rows.reduce(
      (acc, row) => acc + Number(row.IQNTY * row.RATE),
      0
    );
    const rTotal = rows.reduce(
      (acc, row) => acc + Number(row.RQNTY * row.RATE),
      0
    );
    const updatedPartyDetails = rows.map((item) => {
      const isReturnQnty = item.RQNTY > 0;
      const amount = isReturnQnty
        ? item.RQNTY * item.RATE
        : item.IQNTY * item.RATE;
      return {
        ...item,
        SERIAL_NO: item.SERIAL_NO ? item.SERIAL_NO : "",
        ENT_TIME: moment().format("HH:MM:SS"),
        ISSUED_TO: partyDetails.ISSUED_TO,
        ISSUED_BY: partyDetails.ISSUED_BY,
        DEP_NAME: partyDetails.DEP_NAME,
        DATE: moment().format("YYYY-MM-DD"),
        INDENT_NO: partyDetails.INDENT_NO,
        TOTAL: iTotal.toFixed(2),
        RTOTAL: rTotal.toFixed(2),
        AMOUNT: amount.toFixed(2),
        STOCK_AVL: item.STOCK_AVL ? item.STOCK_AVL : 0,
      };
    });
    console.log(updatedPartyDetails);

    const filterData = updatedPartyDetails.filter(
      (item) => item.ICODE !== "" && item.ITEMNAME !== ""
    );

    if (
      partyDetails.DEP_NAME &&
      filterData.every((row) => row.ITEMNAME) &&
      filterData.every((row) => row.IQNTY > 0 || row.RQNTY > 0) &&
      filterData[0]
    ) {
      setAlertTwoButtonModal((o) => {
        return {
          ...o,
          status: false,
        };
      });
      savePurchaseAfterCheck(
        prefix,
        filterData,
        partyDetails,
        dispatch,
        token,
        setCurrentINVNO,
        setAlertTwoButtonModal,
        clearState,

        handlePrint,
        setIsClearForm
      );
    } else {
      if (!partyDetails.DEP_NAME) {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Select Department.",
          })
        );
        setPartyFocus();
      } else {
        if (!filterData[0]) {
          setAlertTwoButtonModal((o) => {
            return {
              ...o,
              title: "Alert",
              status: true,
              msg: "Not found any data to save.",
              button1: "Ok",
              button1Click: () => {
                delayedFunction(rows[0].id);
                setAlertTwoButtonModal((e) => {
                  return {
                    ...e,
                    status: false,
                  };
                });
              },
            };
          });
        } else {
          const itemsWithoutQuantity = filterData.filter(
            (row) => !row.IQNTY && !row.RQNTY
          );

          if (itemsWithoutQuantity.length > 0) {
            const itemNames = itemsWithoutQuantity
              .map((item) => item.ITEMNAME)
              .join(", ");
            setAlertTwoButtonModal({
              title: "Alert",
              msg: ` ${itemNames} item are missing quantity. Please enter quantity / R quantity for that item. `,

              status: true,
              button1: "Ok",
              button1Click: () => {
                setAlertTwoButtonModal((o) => {
                  return {
                    ...o,
                    status: false,
                  };
                });
              },
            });
          } else {
            if (!filterData.every((row) => row.ITEMNAME)) {
              setAlertTwoButtonModal({
                title: "Alert",
                msg: "Please select item for save records",

                status: true,
                button1: "Ok",
                button1Click: () => {
                  setAlertTwoButtonModal((o) => {
                    return {
                      ...o,
                      status: false,
                    };
                  });
                },
              });
            }
          }
        }
      }
    }
  };

  const delayedFunction = (id) => {
    setTimeout(() => {
      // Function to execute after 30 seconds
      const nearestInput = document.getElementById(id + "-" + "ICODE");
      if (nearestInput) {
        nearestInput.focus();
      }
    }, 200);
  };

  const loadPurchaseBill = async (serielNo) => {
    CallAPIPromise(
      "/api/issue/loadIssue",
      {
        table_prefix: prefix,
        SERIAL_NO: serielNo,
      },
      token,
      dispatch
    )
      .then((p) => {
        const data = p.data.response;
        console.log(data);
        const res = data.map((h) => {
          return {
            ...h,
            DATE: moment(h.DATE).format("YYYY-MM-DD"),
            id: uniqueId(),
          };
        });

        if (res[0]) {
          setPartyDetails({
            SERIAL_NO: res[0].SERIAL_NO,
            DATE: moment(res[0].DATE).format("YYYY-MM-DD"),
            ENT_TIME: res[0].ENT_TIME,
            INDENT_NO: res[0].INDENT_NO,
            ISSUED_TO: res[0].ISSUED_TO,
            ISSUED_BY: res[0].ISSUED_BY,
            DEP_NAME: res[0].DEP_NAME,
          });
        }

        setRows([
          ...res,
          {
            ...emptyRowDetails,
            id: uniqueId(),
          },
        ]);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      });
  };

  const clearState = () => {
    setRows([
      {
        ...emptyRowDetails,
        id: uniqueId(),
      },
    ]);

    setPartyDetails(defaultPartyDetails);
  };

  const printRef = useRef();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState("print");

  const handlePrint = useReactToPrint({
    pageStyle: `
    @page {
      size: portrait;
    }
  `,
    content: () =>
      selectedPrintOption === "print"
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      clearState();
      // setAccountMasterHelpStatus(true);
      // setSelectedPrintOption("print");
    },
  });
  const handlePrint2 = useReactToPrint({
    pageStyle: `
    @page {
      size: portrait;
    }
  `,
    content: () =>
      selectedPrintOption === "print"
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      // setAccountMasterHelpStatus(true);
      // setSelectedPrintOption("print");
    },
  });

  useEffect(() => {
    function handleKeyPress(event) {
      // Check if Ctrl or Alt key is pressed along with 'S'
      if ((event.ctrlKey || event.altKey) && event.key === "s") {
        event.preventDefault();
        savePurchaseBill();
      }
    }

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [partyDetails, rows]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "1") {
        event.preventDefault();

        setPartyNameFocus();
        // Add your logic here
      }

      if (event.ctrlKey && event.key === "2") {
        event.preventDefault();
        delayedFunction(rows[0].id);
        // Add your logic here
      }

      if (event.ctrlKey && event.key === "3") {
        event.preventDefault();

        setFrieghtFocus();
        // Add your logic here
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (rows[0]?.ICODE && rows[0]?.ITEMNAME) {
      deleteWholeDatabase("issue").then(() => {
        dataToLocal("issue", rows, [
          {
            ...partyDetails,
            ICODE: 1,
          },
        ]);
      });
    }
  }, [rows]);

  useEffect(() => {
    const callback = (details, data) => {
      if (data[0]) {
        setAlertTwoButtonModal({
          title: "Unsaved Data",
          msg: "We found an unsaved Purchase. Do you want to load it?",
          status: true,
          button1: "Yes",
          button2: "Cancel",
          button1Click: () => {
            setPartyDetails(details[0]);
            setRows(data);
            setAlertTwoButtonModal((o) => {
              return {
                ...o,
                status: false,
              };
            });
          },

          button2Click: () => {
            deleteWholeDatabase("issue");
            setAlertTwoButtonModal((o) => {
              return {
                ...o,
                status: false,
              };
            });
          },
        });
      }
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("issue", callback, elseFunc);
  }, []);

  const width = useSelector(selectWidth);
  const data = {
    sellerInfo: {
      name: partyDetails.NAME,
      address: partyDetails.ADDRESS,
      gstin: partyDetails.TAX_NUMBER,
      phone: partyDetails.MOBILE,
    },
    invoiceDetails: {
      invoiceNo: partyDetails.PUR_BILL_NO,
      date: partyDetails.PUR_BILL_DATE,
      destination: "",
      gstin: partyDetails.TAX_NUMBER,
    },
    columns: PrintColumns(width),
    items: [
      {
        description: "INTEL CORE I5",
        hsn: "8473",
        quantity: 1,
        unitRate: 17800.0,
        cgst: "9%",
        sgst: "9%",
        amount: 17800.0,
      },
      // Add other items here
    ],
    totals: {
      taxableAmount: "37,570.00",
      cgst: "2,865.51",
      sgst: "2,865.51",
      grandTotal: "37,570.00",
    },
  };
  return (
    <div className=" px-3 py-2 bg-[#f9fafc] h-full text-black text-sm overflow-y-auto">
      <PartyDetails
        currentDate={currentDate}
        BillModeOption={BillModeOption}
        TaxTypeOption={TaxTypeOption}
        BillTypeOption={BillTypeOption}
        partyDetails={partyDetails}
        setPartyDetails={setPartyDetails}
        duplicatePurchaseBill={duplicatePurchaseBill}
        setDuplicatePurcahseBill={setDuplicatePurcahseBill}
        dispatch={dispatch}
        rows={rows}
        issueToRef={issueToRef}
        setIssueToRef={setIssueToRef}
        issuedByRef={issuedByRef}
        setIssuedByRef={setIssuedByRef}
        refreshList={refreshList}
        partyNameRef={partyNameRef}
        muiTextBox={muiTextBox}
        accountMasterHelpStatus={accountMasterHelpStatus}
        setAccountMasterHelpStatus={setAccountMasterHelpStatus}
        loadPurchaseBill={loadPurchaseBill}
        purchaseRef={purchaseRef}
        partyRef={partyRef}
        purchaseAmtRef={purchaseAmtRef}
        setPurcahseAmtFocus={setPurcahseAmtFocus}
        setPurchaseBillNoFocus={setPurchaseBillNoFocus}
        purchaseBillList={purchaseBillList}
        setPartyFocus={setPartyFocus}
        setInputRefFoucs={setInputRefFoucs}
        delayedFunction={delayedFunction}
        setPartyMasterCreaterModalStatus={setPartyMasterCreaterModalStatus}
      />
      <Datagrid
        delayedFunction={delayedFunction}
        savePurchaseBill={savePurchaseBill}
        inputRef={inputRef}
        setBarCodeListStatus={setBarCodeListStatus}
        setBarCodeList={setBarCodeList}
        setItemMasterStatus={setItemMasterStatus}
        partyDetails={partyDetails}
        setPartyDetails={setPartyDetails}
        setAccountMasterHelpStatus={setAccountMasterHelpStatus}
        rows={rows}
        setIsClearForm={setIsClearForm}
        setRows={setRows}
        currentINVNO={currentINVNO}
        clearState={clearState}
        handlePrint={handlePrint}
        handlePrint2={handlePrint2}
        alertTwoButtonModal={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ItemMasterModal
        status={itemMasterStatus}
        handleClose={handleClose}
        setRows={setRows}
        rows={rows}
        setItemMasterStatus={setItemMasterStatus}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <PartyMasterCreaterModal
        afterCreate={() => {
          setPartyMasterCreaterModalStatus(false);
          setRefreshList((o) => !o);
        }}
        status={partyMasterCreaterModalStatus}
        hidePartyList={true}
        onClose={() => {
          setPartyMasterCreaterModalStatus(false);
        }}
      />
      <BarcodeList
        status={barCodeListStatus}
        list={barCodeList}
        onClose={() => {
          setBarCodeListStatus(false);
        }}
        handleBillPrint={handlePrint}
        printBarRef={printBarRef}
      />
      <div
        style={{
          display: "none",
        }}
      >
        <div ref={printRef}>
          <IssuePrint
            data={data}
            partyDetails={partyDetails}
            rows={rows.filter((row) => row.ITEMNAME !== "")}
          />
        </div>
      </div>
    </div>
  );
};

export default Issue;
function savePurchaseAfterCheck(
  prefix,
  filterData,
  partyDetails,
  dispatch,
  token,
  setCurrentINVNO,
  setAlertTwoButtonModal,
  clearState,
  handlePrint,
  setIsClearForm
) {
  const data = {
    table_prefix: prefix,
    cart_issue_item: filterData,
    NEW_MODIFY: filterData[0].SERIAL_NO ? "Modify" : "New",
  };
  {
    partyDetails.SERIAL_NO
      ? dispatch(setSpinnerLoading("Updating"))
      : dispatch(setSpinnerLoading("Saving"));
  }
  CallAPI(
    "/api/issue/saveIssue",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        deleteWholeDatabase("issue");
        setCurrentINVNO(e.data.SERIAL_NO);
        const SERIAL_NO = e.data.SERIAL_NO;
        setAlertTwoButtonModal((e) => {
          return {
            ...e,
            title: partyDetails.SERIAL_NO
              ? `Invoice - ${partyDetails.SERIAL_NO} `
              : `Invoice - ${SERIAL_NO}`,
            msg: "Data Saved",
            status: true,
            button1: "Ok",
            button1Bg: "gray",

            button2Bg: "#34b4eb",
            button2: "Print",
            button1Click: () => {
              setAlertTwoButtonModal((e) => {
                return {
                  ...e,
                  status: false,
                };
              });

              clearState();
            },
            button2Click: () => {
              setAlertTwoButtonModal((e) => {
                return {
                  ...e,
                  status: false,
                };
              });
              setIsClearForm(true);
              handlePrint();
            },
          };
        });
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
}
