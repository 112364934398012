import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import dataToLocal from "../../utility/component/dataToLocal";

export const GetItemWisePurchaseSummary = (
  data,
  token,
  dispatch,
  setPurchaseList,
  setFullItemWiseReport,
  setSummaryList,
  settotalPages,
  setCurrentPage
) => {
  dispatch(setSpinnerLoading("Loading..."));
  if (data.DEP_NAME === "") {
    deleteWholeDatabase("dep_wise_issue_items");
    deleteWholeDatabase("dep_wise_issue_department");
  }
  CallAPI(
    "/api/issueReports/DepartmentWiseIssue",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        const res = e.data.data;
        settotalPages(e.data.pagination.totalPages);
        setCurrentPage(e.data.pagination.currentPage);
        setSummaryList(e.data.departmentSummary);
        if (res[0]) {
          setPurchaseList(res);
          if (data.DEP_NAME === "") {
            dataToLocal("dep_wise_issue_items", res, [
              {
                ...data,
                ICODE: 1,
              },
            ]);
            dataToLocal("dep_wise_issue_department", e.data.departmentSummary, [
              {
                ...data,
                ICODE: 1,
              },
            ]);
            setFullItemWiseReport(res);
          }
        } else {
          setPurchaseList([]);

          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "No data found",
            })
          );
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};

export const GetSectionWisePurchaseSummary = (
  data,
  token,
  dispatch,
  setSummaryList
) => {
  deleteWholeDatabase("dep_wise_issue");

  CallAPI(
    "/api/master/listDepartmentMaster",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          dataToLocal("dep_wise_issue", e.data.response, [
            {
              ...data,
              ICODE: 1,
            },
          ]);
        }
        setSummaryList(e.data.response);
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch
  );
};
