import React, { useEffect, useState } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import styles from "../../utility/styles/Signup.module.css";
import loginStyle from "../../utility/styles/LoginForm.module.css";
import {
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
} from "../../utility/Redux/modal";
import { useDispatch } from "react-redux";
import { setLogin } from "../../utility/Redux/security";
import {
  setCompany,
  setCompanyAddress,
  setFinancialYearDetails,
  setPrefix,
  setUserName,
  setUserType,
  setemail,
} from "../../utility/Redux/profile";
import GeniusFooterLogo from "../../utility/images/ico.png";
import AlertModal2 from "../../utility/component/modals/alertWithLottie";
import SpinnerLoading from "../../utility/component/modals/spinnerLoading";
import RetailxLogo from "../../utility/component/reatilxLogo";
import { isTablet } from "react-device-detect";
import { CallAPIPromise } from "../../billing/common";

const FinancialYear = () => {
  const dispatch = useDispatch();

  const [userDetails, setuserDetails] = useState("");
  // const [company, setCompany] = useState('');
  const navigate = useNavigate();
  const companyDetailsLocal = JSON.parse(
    localStorage.getItem("companyDetails")
  );
  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");
  const [finacialYearDetails, setfinancialYear] = useState("");
  const location = useLocation();
  const CompanyEmail = location?.state?.CompanyEmail;
  const UserEmail = location?.state?.UserEmail;
  const [companyDetail, setcompanyDetail] = useState([]);
  const getCompanyList = () => {
    if (CompanyEmail) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      const email = CompanyEmail;
      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      const data = {
        company_email: CompanyEmail,
        user_email: UserEmail,
        user_mobile: "",
        company_mobile: "",
      };

      CallAPIPromise(`/api/users/userCompanyInfo`, data, hmacString, dispatch)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            setcompanyDetail(data);
            if (companyDetailsLocal?.companyName === data[0]?.company_name) {
              const selectedCompany = data.find(
                (company) =>
                  company.company_name === companyDetailsLocal.companyName
              );
              setuserDetails(selectedCompany);
              getFinacialYear(
                selectedCompany.table_prefix,
                selectedCompany.user_email,
                selectedCompany.company_email
              );
            }
          } else {
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setAlertMsg("Something went wrong."));
          dispatch(setALertStatus(true));
        });
    }
  };

  const getFinacialYear = (table_prefix, user_email, company_email) => {
    if ((table_prefix, user_email, company_email)) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(user_email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      const data = {
        table_prefix: table_prefix,
        user_email: user_email,
        company_email: company_email,
      };
      CallAPIPromise(`/api/users/financialYear`, data, hmacString, dispatch)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            setfinancialYear(data.response);
            if (companyDetailsLocal?.financialYear) {
              setSelectedFinancialYear((e) => {
                const d = data.response.filter(
                  (d) => d.year === companyDetailsLocal.financialYear
                );
                return d[0];
              });
            }
          } else {
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          dispatch(setAlertMsg("Something went wrong."));
          dispatch(setALertStatus(true));
        });
    }
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  const secretPass = process.env.REACT_APP_SECRET_KEY;

  const encryptEmail = (email) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();

    localStorage.setItem("email", data);
    dispatch(setemail(email));
  };

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem("prefix", data);
  };

  const encryptMasterPrefix = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem("masterPrefix", data);
  };

  const encryptUserType = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem("userType", data);
  };

  const encryptDevMode = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem("dev", data);
  };

  const submitButton = () => {
    if (userDetails) {
      if (selectedFinancialYear) {
        encryptEmail(UserEmail);
        const text = userDetails.company_name;
        const words = text.split(" ");

        const result =
          words.reduce((acc, word) => {
            if (word) {
              acc += word[0];
            }
            return acc;
          }, "") + "_";

        dispatch(setLogin(true));
        dispatch(setUserName(userDetails.user_name));
        dispatch(setCompany(userDetails.company_name));
        dispatch(setPrefix(userDetails.table_prefix));
        // dispatch(setPrefix("go_20232024_"));
        dispatch(setFinancialYearDetails(selectedFinancialYear));
        localStorage.setItem(
          "financial",
          JSON.stringify({
            ...selectedFinancialYear,
          })
        );
        console.log(userDetails);
        localStorage.setItem(
          "companyDetails",
          JSON.stringify({
            address: userDetails.address,
            city: userDetails.city,
            state: userDetails.state,
            mobile: userDetails.company_mobile,
            email: userDetails.company_email,
            companyName: userDetails.company_name,
            companyWebsite: userDetails.company_website,
            start_date: userDetails.start_date,
            end_date: userDetails.end_date,
            regdNo: userDetails.regd_no1,
            financialYear: selectedFinancialYear?.year,
            currency_symbol: userDetails.currency_symbol,
          })
        );
        dispatch(
          setCompanyAddress({
            address: userDetails.address,
            city: userDetails.city,
            state: userDetails.state,
            mobile: userDetails.company_mobile,
            email: userDetails.company_email,
            companyName: userDetails.company_name,
            companyWebsite: userDetails.company_website,
            regdNo: userDetails.regd_no1,
            currency_symbol: userDetails.currency_symbol,
          })
        );
        encryptData(userDetails.table_prefix);
        encryptMasterPrefix();
        encryptUserType(userDetails.user_type);
        // encryptDevMode(userDetails.developer_yn);
        localStorage.setItem("company", userDetails.company_name);
        localStorage.setItem("userName", userDetails.user_name);
        dispatch(setUserType(userDetails.user_type));

        localStorage.removeItem("lastDate");
        navigate("/");
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Financial Year",
            msg: "Please Select Financial Year",
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Company name",
          msg: "Please Select Company Name",
        })
      );
    }
  };

  useEffect(() => {
    if (!CompanyEmail) {
      navigate("/auth/login");
    }
  }, []);

  return (
    <div className="h-screen px-2">
      <div
        style={{
          height: isTablet ? `calc(100% - 90px)` : "100%",
          alignItems: isTablet ? "start" : "center",
          paddingTop: isTablet ? "20px" : "0px",
        }}
        className="flex justify-center items-center max-w-[411px]    sm:py-1 px-1"
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            boxShadow: "2px 3px 6px #a8aaad",
          }}
        >
          <div className={styles.loginForm} style={{ height: "442px" }}>
            <RetailxLogo />
            <div className="text-[#554f4f] font-segoe flex justify-center mt-2 font-[500] text-[20px]">
              Company Login
            </div>
            <div className={`{loginStyle.formGroup} mt-3`}>
              <label className={loginStyle.label}>Select Company</label>
              <select
                style={{
                  width: "100%",
                  outline: "none",
                  border: "1px solid #b5b3b3",
                  borderRadius: "7px",
                }}
                id="SelectCompany"
                name="SelectCompany"
                value={userDetails?.company_name}
                className={` text-black p-[11px]`}
                onChange={(event) => {
                  const selectedCompany = companyDetail.find(
                    (company) => company.company_name === event.target.value
                  );
                  if (selectedCompany) {
                    localStorage.setItem(
                      "financial_company",
                      JSON.stringify(selectedCompany)
                    );
                  }
                  setuserDetails(selectedCompany);
                  getFinacialYear(
                    selectedCompany.table_prefix,
                    selectedCompany.user_email,
                    selectedCompany.company_email
                  );
                }}
              >
                <option value=""></option>
                {companyDetail.map((company, key) => (
                  <option
                    key={key}
                    value={company.company_name}
                    style={{ fontSize: "16px" }}
                  >
                    {company.company_name}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`{loginStyle.formGroup} mt-3`}
              style={{ position: "relative" }}
            >
              <label className={loginStyle.label}>Select Financial Year</label>
              <select
                style={{
                  width: "100%",
                  outline: "none",
                  border: "1px solid #b5b3b3",
                  borderRadius: "7px",
                }}
                value={selectedFinancialYear?.year}
                id="SelectFinancialYear"
                className={`p-[11px] text-black`}
                onChange={(event) => {
                  setSelectedFinancialYear((e) => {
                    const d = finacialYearDetails.filter(
                      (d) => d.year === event.target.value
                    );
                    localStorage.setItem("financial", JSON.stringify(d[0]));
                    return d[0];
                  });
                }}
              >
                <option value=""></option>

                {finacialYearDetails[0] ? (
                  finacialYearDetails.map((year, key) => (
                    <option
                      key={key}
                      value={year.year}
                      style={{ fontSize: "16px" }}
                    >
                      {year.year}
                    </option>
                  ))
                ) : (
                  <option value={""}>No financial years available</option>
                )}
              </select>
            </div>

            <div className="mt-4">
              <button className={loginStyle.button} onClick={submitButton}>
                Submit
              </button>
            </div>
          </div>

          <div
            className="bg-[#F1F5F9] p-4"
            style={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <div className="text-[11.5px] whitespace-nowrap sm:text-[15px] items-center w-full justify-center flex font-ubuntu">
              A product of
              <div className="flex justify-center items-center px-1 mt-1">
                <img src={GeniusFooterLogo} alt="" width={18} />
              </div>
              <label className="font-semibold pr-1">genius office</label>
              2023. All rights reserved.
            </div>
          </div>
        </div>
      </div>

      <AlertModal2 />
      <SpinnerLoading />
    </div>
  );
};
export default FinancialYear;
