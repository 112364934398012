import moment from "moment";
import { FormatNumber2 } from "../utility/functions/formatNumber";

export const IssueColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      headerAlign: "center",
      width: "15%",
      align: "left",
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Code
        </div>
      ),
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      headerAlign: "center",
      align: "left",

      width: "20%",

      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Item Name
        </div>
      ),
    },
    {
      field: "UNIT",
      accessor: "UNIT",
      type: "string",
      headerAlign: "center",
      align: "left",

      width: "10%",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Unit
        </div>
      ),
    },
    {
      field: "PACKING",
      accessor: "PACKING",
      type: "string",
      headerAlign: "center",
      align: "left",

      width: "10%",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Packing
        </div>
      ),
    },

    {
      field: "IQNTY",
      accessor: "IQNTY",
      type: "text",
      align: "left",

      width: "10%",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Quantity
        </div>
      ),
    },
    {
      field: "RQNTY",
      accessor: "RQNTY",
      type: "text",
      align: "left",
      isLast: true,
      width: "10%",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Return Qnty
        </div>
      ),
    },
    {
      field: "RATE",
      accessor: "RATE",
      type: "number",
      width: "5%",
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Rate
        </div>
      ),
    },

    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: "10%",
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Amount
        </div>
      ),
    },

    {
      field: "STOCK_AVL",
      accessor: "STOCK_AVL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: "10%",
      align: "right",
      editable: false,

      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Stock Available
        </div>
      ),
    },
  ];

  return columns;
};
