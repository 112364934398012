import React, { useState } from "react";
import GeniustextInput from "../utility/component/mastertext";
import AccountMasterHelp from "../help/listAccountMasterHelp";
import PurchaseBillHelp from "../help/purchaseBillHelp";
import { setAlertWithTitle } from "../utility/Redux/modal";
import TextBoxMui from "../utility/component/textBoxMui";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDefultProfileSetting,
  selectWidth,
} from "../utility/Redux/profile";
import OutlinedInputCustom from "../utility/component/textInput";
import DropdownWithSearch from "../utility/component/dropDown";
import { DepartmentMasterHelp } from "../help";
import IssueBillHelp from "../help/issueHelp";

const PartyDetails = ({
  partyDetails,
  setPartyDetails,
  refreshList,
  loadPurchaseBill,
  accountMasterHelpStatus,
  setAccountMasterHelpStatus,
  rows,
  delayedFunction,
  setPurchaseBillNoFocus,
  partyNameRef,
  setPartyMasterCreaterModalStatus,
  issueToRef,
  setIssueToRef,
  issuedByRef,
  setIssuedByRef,
}) => {
  const [IssueBillHelpStatus, setIssueBillHelpStatus] = useState(false);
  return (
    <div className="flex h-auto w-auto py-2  ">
      <div
        style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
        className="pt-3 px-3 pb-3   bg-[#ffffff]"
      >
        <div className="-mt-7 bg-white text-[15px] font-semibold w-fit px-2">
          Issue Metarial
        </div>
        <div className="grid grid-cols-[160px,190px]  d:grid-cols-[200px,150px,180px] gap-3 mt-3 w-full ">
          <OutlinedInputCustom
            readOnly={true}
            value={partyDetails.SERIAL_NO}
            label={"Serial No."}
            fontWeight={600}
            search={() => {
              setIssueBillHelpStatus(true);
            }}
          />
          <OutlinedInputCustom
            onChange={(e) => {
              setPartyDetails((p) => {
                return {
                  ...p,
                  INDENT_NO: e.target.value,
                };
              });
            }}
            value={partyDetails.INDENT_NO}
            label={"Indent No."}
          />

          <OutlinedInputCustom
            type={"date"}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
                setPartyDetails((prevState) => ({
                  ...prevState,
                  DATE: inputValue,
                }));
              }
            }}
            value={partyDetails.DATE}
            label={"Date"}
          />
        </div>
      </div>

      <div
        style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
        className=" bg-white grid grid-cols-3 gap-3 p-3 flex-1 ml-3"
      >
        <div className="">
          <OutlinedInputCustom
            search={() => {
              setAccountMasterHelpStatus(true);
            }}
            onChange={() => {
              setAccountMasterHelpStatus(true);
            }}
            width={`calc(100% - 0px)`}
            value={partyDetails.DEP_NAME}
            label={"Department Name"}
            autoFocus={true}
            refrance={partyNameRef}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                if (partyDetails.DEP_NAME) {
                  setIssueToRef(true);
                } else {
                  setAccountMasterHelpStatus(true);
                }
              }
            }}
          />
        </div>

        <OutlinedInputCustom
          width={`calc(100% - 0px)`}
          value={partyDetails.ISSUED_TO}
          label={"Issues To"}
          autoFucus={true}
          refrance={issueToRef}
          onChange={(e) => {
            setPartyDetails((p) => {
              return {
                ...p,
                ISSUED_TO: e.target.value,
              };
            });
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
              setIssuedByRef(true);
            }
          }}
        />
        <OutlinedInputCustom
          width={`calc(100% - 0px)`}
          value={partyDetails.ISSUED_BY}
          label={"Issued By"}
          autoFucus={true}
          onChange={(e) => {
            setPartyDetails((p) => {
              return {
                ...p,
                ISSUED_BY: e.target.value,
              };
            });
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
              delayedFunction(rows[0].id);
            }
          }}
          refrance={issuedByRef}
        />
      </div>

      <DepartmentMasterHelp
        onRowClick={(row) => {
          setAccountMasterHelpStatus(false);
          setIssueToRef(true);
          setPartyDetails((old) => {
            return {
              ...old,
              DEP_NAME: row.row.DEP_NAME,
            };
          });

          setTimeout(() => {
            setIssueToRef(true);
          }, 500);
        }}
        status={accountMasterHelpStatus}
        onClose={() => {
          setAccountMasterHelpStatus(false);
        }}
      />
      <IssueBillHelp
        onRowClick={(row) => {
          delayedFunction(rows[0].id);
          setIssueBillHelpStatus(false);
          loadPurchaseBill(row.row.SERIAL_NO);
        }}
        status={IssueBillHelpStatus}
        onClose={() => {
          setIssueBillHelpStatus(false);
        }}
      />
    </div>
  );
};

export default PartyDetails;
