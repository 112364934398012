import React, { useState } from "react";
import GeniustextInput from "../utility/component/mastertext";
import AccountMasterHelp from "../help/listAccountMasterHelp";
import PurchaseBillHelp from "../help/purchaseBillHelp";
import { setAlertWithTitle } from "../utility/Redux/modal";
import TextBoxMui from "../utility/component/textBoxMui";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDefultProfileSetting,
  selectWidth,
} from "../utility/Redux/profile";
import OutlinedInputCustom from "../utility/component/textInput";
import DropdownWithSearch from "../utility/component/dropDown";

const PartyDetails = ({
  partyDetails,
  setPartyDetails,
  refreshList,
  getBillData,
  loadPurchaseBill,
  duplicatePurchaseBill,
  accountMasterHelpStatus,
  setAccountMasterHelpStatus,
  BillModeOption,
  rows,
  delayedFunction,
  TaxTypeOption,
  setPurcahseAmtFocus,
  BillTypeOption,
  purchaseAmtRef,
  setInputRefFoucs,
  purchaseRef,
  partyRef,
  muiTextBox,
  setPurchaseBillNoFocus,
  partyNameRef,
  setPartyMasterCreaterModalStatus,
}) => {
  const [purchaseBillHelpStatus, setPurchaseBillHelpStatus] = useState(false);
  const windowWidth = useSelector(selectWidth);
  const dispatch = useDispatch();
  return (
    <div className="flex h-auto w-auto py-2  ">
      <div
        style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
        className="pt-3 px-3 pb-3   bg-[#ffffff]"
      >
        <div className="-mt-7 bg-white text-[15px] font-semibold w-fit px-2">
          Purchase Bill Details
        </div>
        <div className="grid grid-cols-[160px,190px]  d:grid-cols-[200px,150px,180px] gap-3 mt-3 w-full ">
          {muiTextBox ? (
            <OutlinedInputCustom
              readOnly={true}
              value={partyDetails.INVNO}
              label={"Serial No."}
              search={() => {
                if (true) {
                  setPurchaseBillHelpStatus(true);
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: `You don't have permission to modify the purchase.`,
                    })
                  );
                }
              }}
            />
          ) : (
            <GeniustextInput
              title={"Serial No."}
              onClick={() => {}}
              type={"text"}
              value={partyDetails.INVNO}
              width={windowWidth <= 1300 ? "110px" : "120px"}
              search={() => {
                setPurchaseBillHelpStatus(true);
              }}
            />
          )}
          {muiTextBox ? (
            <DropdownWithSearch
              options={BillModeOption}
              onChange={(t) =>
                setPartyDetails((e) => {
                  return {
                    ...e,
                    BILL_MODE: t,
                  };
                })
              }
              value={partyDetails.BILL_MODE}
              label={"Bill Mode"}
            />
          ) : (
            <GeniustextInput
              title={"Bill Mode"}
              option={BillModeOption}
              value={partyDetails.BILL_MODE}
              onChange={(t) =>
                setPartyDetails((e) => {
                  return {
                    ...e,
                    BILL_MODE: t.target.value,
                  };
                })
              }
              width={windowWidth <= 1300 ? "110px" : "160px"}
            />
          )}

          {muiTextBox ? (
            <DropdownWithSearch
              options={TaxTypeOption}
              onChange={(t) =>
                setPartyDetails((e) => {
                  return {
                    ...e,
                    TAX_TYPE: t,
                  };
                })
              }
              value={partyDetails.TAX_TYPE}
              label={"Tax Type"}
            />
          ) : (
            <GeniustextInput
              title={"Tax Type"}
              option={TaxTypeOption}
              value={partyDetails.TAX_TYPE}
              onChange={(t) =>
                setPartyDetails((e) => {
                  return {
                    ...e,
                    TAX_TYPE: t.target.value,
                  };
                })
              }
              width={windowWidth <= 1300 ? "200px" : "160px"}
            />
          )}
          {muiTextBox ? (
            <DropdownWithSearch
              options={BillTypeOption}
              onChange={(t) =>
                setPartyDetails((e) => {
                  return {
                    ...e,
                    BILL_TYPE: t,
                  };
                })
              }
              value={partyDetails.BILL_TYPE}
              label={"Bill Type"}
            />
          ) : (
            <GeniustextInput
              title={"Bill Type"}
              value={partyDetails.BILL_TYPE}
              onChange={(t) =>
                setPartyDetails((e) => {
                  return {
                    ...e,
                    BILL_TYPE: t.target.value,
                  };
                })
              }
              option={BillTypeOption}
              width={windowWidth <= 1300 ? "110px" : "160px"}
            />
          )}
          {muiTextBox ? (
            <OutlinedInputCustom
              type={"date"}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
                  setPartyDetails((prevState) => ({
                    ...prevState,
                    DATE: inputValue,
                  }));
                }
              }}
              value={partyDetails.DATE}
              label={"Date"}
            />
          ) : (
            <GeniustextInput
              title={"Date"}
              type={"date"}
              value={partyDetails.DATE}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
                  setPartyDetails((prevState) => ({
                    ...prevState,
                    DATE: inputValue,
                  }));
                }
              }}
              width={windowWidth <= 1300 ? "110px" : "160px"}
            />
          )}
        </div>
      </div>

      <div
        style={{ border: "1px solid #b5b3b3", borderRadius: "8px" }}
        className=" bg-white grid grid-cols-[1fr,150px] 3xl:grid-cols-[600px,1fr,1fr] 2xl:grid-cols-[450px,1fr,1fr] ll:grid-cols-[1fr,160px,150px] gap-3 p-3 flex-1 ml-3"
      >
        <div className="flex ">
          {muiTextBox ? (
            <OutlinedInputCustom
              search={() => {
                setAccountMasterHelpStatus(true);
              }}
              onChange={() => {
                setAccountMasterHelpStatus(true);
              }}
              width={`calc(100% - 110px)`}
              value={partyDetails.NAME}
              label={"Party Name"}
              autoFucus={true}
              refrance={partyNameRef}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                  if (partyDetails.NAME) {
                    setPurchaseBillNoFocus(true);
                  } else {
                    setAccountMasterHelpStatus(true);
                  }
                }
              }}
            />
          ) : (
            // <TextBoxMui
            //   onKeyDown={(e) => {
            //     if (e.code === "Enter" || e.code === "NumpadEnter") {
            //       if (partyDetails.NAME) {
            //         setPurchaseBillNoFocus(true);
            //       } else {
            //         setAccountMasterHelpStatus(true);
            //       }
            //     }
            //   }}
            //   search={() => {
            //     setAccountMasterHelpStatus(true);
            //   }}
            //   onChange={() => {
            //     setAccountMasterHelpStatus(true);
            //   }}
            //   width={`calc(100% - 110px)`}
            //   value={partyDetails.NAME}
            //   label={"Party Name"}
            //   autoFucus={true}
            //   refrance={partyNameRef}
            // />
            <GeniustextInput
              title={"Party Name"}
              type={"text"}
              value={partyDetails.NAME}
              search={() => {
                setAccountMasterHelpStatus(true);
              }}
              onChange={() => {
                setAccountMasterHelpStatus(true);
              }}
              refrance={partyRef}
              width={windowWidth <= 1300 ? "200px" : "280px"}
            />
          )}
          <div className="ml-2">
            {muiTextBox ? (
              <OutlinedInputCustom
                type={"number"}
                readOnly={true}
                width={"110px"}
                value={partyDetails.ACCOUNT_NO}
                label={"Account No."}
              />
            ) : (
              <GeniustextInput
                title={"Account No."}
                type={"number"}
                width={windowWidth <= 1300 ? "100px" : "110px"}
                value={partyDetails.ACCOUNT_NO}
                readOnly
              />
            )}
          </div>
        </div>

        {muiTextBox ? (
          <OutlinedInputCustom
            readOnly={true}
            type={"number"}
            value={partyDetails.MOBILE}
            label={"Mobile"}
          />
        ) : (
          <GeniustextInput
            title={"Mobile"}
            value={partyDetails.MOBILE}
            type={"number"}
            width={windowWidth <= 1300 ? "320px" : "400px"}
            readOnly
          />
        )}
        {muiTextBox ? (
          <OutlinedInputCustom
            type={"date"}
            onChange={(e) => {
              console.log(e.target.value);
              const inputValue = e.target.value;
              if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
                setPartyDetails((prevState) => ({
                  ...prevState,
                  PUR_BILL_DATE: inputValue,
                }));
              }
            }}
            value={partyDetails.PUR_BILL_DATE}
            label={"Bill Date"}
          />
        ) : (
          <GeniustextInput
            title={"Bill Date"}
            type={"date"}
            width={windowWidth <= 1300 ? "135px" : "170px"}
            value={partyDetails.PUR_BILL_DATE}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) {
                setPartyDetails((prevState) => ({
                  ...prevState,
                  PUR_BILL_DATE: inputValue,
                }));
              }
            }}
          />
        )}

        <div className="">
          {muiTextBox ? (
            <OutlinedInputCustom
              readOnly={true}
              type={"text"}
              width={"100%"}
              value={partyDetails.TAX_NUMBER}
              label={"GST No."}
            />
          ) : (
            <GeniustextInput
              title={"GST No."}
              type={"text"}
              width={windowWidth <= 1300 ? "320px" : "400px"}
              value={partyDetails.TAX_NUMBER}
              readOnly
            />
          )}
        </div>

        {muiTextBox ? (
          <OutlinedInputCustom
            refrance={purchaseRef}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                setPurcahseAmtFocus(true);
              }
            }}
            id={"PUR_BILL_NO"}
            type={"text"}
            onChange={(e) => {
              if (true) {
                const isDuplicate = [];
                if (!isDuplicate[0]) {
                  setPartyDetails((o) => {
                    return {
                      ...o,
                      PUR_BILL_NO: e.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Already Exist this Nill No.",
                    })
                  );
                }
              }
            }}
            value={partyDetails.PUR_BILL_NO}
            label={"Purchase Bill No."}
          />
        ) : (
          <GeniustextInput
            title={"Purchase Bill No."}
            type={"text"}
            refrance={purchaseRef}
            value={partyDetails.PUR_BILL_NO}
            onChange={(e) => {
              if (true) {
                const isDuplicate = [];
                if (!isDuplicate[0]) {
                  setPartyDetails((o) => {
                    return {
                      ...o,
                      PUR_BILL_NO: e.target.value,
                    };
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Already Exist this Nill No.",
                    })
                  );
                }
              }
            }}
            width={windowWidth <= 1300 ? "135px" : "170px"}
            error={
              duplicatePurchaseBill ? "Purcahse Bill No. already exist." : false
            }
          />
        )}

        {muiTextBox ? (
          <OutlinedInputCustom
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                delayedFunction(rows[0].id);
              }
            }}
            refrance={purchaseAmtRef}
            type={"number"}
            onChange={(e) =>
              setPartyDetails((o) => {
                return {
                  ...o,
                  PUR_BILL_AMOUNT: e.target.value,
                };
              })
            }
            id={"PUR_BILL_AMOUNT"}
            onFocus={(e) => e.target.select()}
            value={partyDetails.PUR_BILL_AMOUNT}
            label={
              windowWidth <= 1300 ? "Purchase Bill Amt." : "Purchase Bill Amt."
            }
          />
        ) : (
          <GeniustextInput
            title={
              windowWidth <= 1300
                ? "Purchase Bill Amt."
                : "Purchase Bill Amount"
            }
            type={"number"}
            width={windowWidth <= 1300 ? "135px" : "170px"}
            placeholder={"0.00"}
            value={partyDetails.PUR_BILL_AMOUNT}
            onChange={(e) =>
              setPartyDetails((o) => {
                return {
                  ...o,
                  PUR_BILL_AMOUNT: e.target.value,
                };
              })
            }
          />
        )}
      </div>

      <AccountMasterHelp
        onRowClick={(row) => {
          setAccountMasterHelpStatus(false);
          setPartyDetails((old) => {
            return {
              ...old,
              NAME: row.row.NAME,
              ADDRESS: row.row.ADDRESS,
              MOBILE: row.row.MOBILE,
              TAX_NUMBER: row.row.TAX_NUMBER,
              ACCOUNT_NO: row.row.ACCOUNT_NO,
            };
          });

          const nearestInput = document.getElementById("PUR_BILL_NO");
          // Focus on the nearest input if it exists
          if (nearestInput) {
            setTimeout(() => {
              nearestInput.focus();
            }, 200);
          }
        }}
        status={accountMasterHelpStatus}
        onClose={() => {
          setAccountMasterHelpStatus(false);
        }}
        onCreatePartyClick={() => {
          setPartyMasterCreaterModalStatus(true);
        }}
        refreshList={refreshList}
      />
      <PurchaseBillHelp
        onRowClick={(row) => {
          delayedFunction(rows[0].id);
          setPurchaseBillHelpStatus(false);
          loadPurchaseBill(row.row.PUR_BILL_NO, row.row.ACCOUNT_NO);
        }}
        status={purchaseBillHelpStatus}
        onClose={() => {
          setPurchaseBillHelpStatus(false);
        }}
      />
    </div>
  );
};

export default PartyDetails;
