const FirstIcodeItem = (id, qnty, item, rqnty = 0) => {
  const pur_price = Number(item.PUR_PRICE ? item.PUR_PRICE : item.RATE);
  const iqnty = rqnty > 0 ? 0 : qnty;
  const rQnty_c = qnty > 0 ? 0 : rqnty;
  console.log(item);
  return {
    id: id,
    ICODE: item.ICODE,
    ALIAS_CODE: item.ALIAS_CODE,
    ITEMNAME: item.ITEMNAME,
    PACKING: item.PACKING,
    UNIT: item.UNIT,
    SECTION_NAME: item.SECTION_NAME,
    SUB_SEC_NAME: item.SUB_SEC_NAME,
    TAX1_RATE: item.TAX1_RATE,
    TAX2_RATE: item.TAX2_RATE,
    TAX3_RATE: item.TAX3_RATE,
    IQNTY: iqnty,
    RQNTY: rQnty_c,
    STOCK_AVL: item.STOCK_AVL ? item.STOCK_AVL : item.BAL_STOCK,
    ITEM_DESC: item.ITEM_DESC ? item.ITEM_DESC : "",
    AMOUNT: rQnty_c > 0 ? rQnty_c * pur_price : iqnty * pur_price,
    TOTAL: 0,
    RTOTAL: 0,
    RATE: pur_price,
  };
};

export default FirstIcodeItem;
