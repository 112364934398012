import { FormatNumber2 } from "../utility/functions/formatNumber";

export const PrintColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      headerName: "Code",
      type: "string",
      headerAlign: "center",
      width: 43,
      headerClassName: "super-app-theme--header",
      editable: true,

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          S.No.
        </div>
      ),
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      headerName: "Item Name",

      type: "string",
      headerAlign: "center",

      width: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Description
        </div>
      ),
    },

    {
      field: "PACKING",
      accessor: "PACKING",
      type: "number",
      headerName: "Packing",

      width: 50,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Packing
        </div>
      ),
    },
    {
      field: "UNIT",
      accessor: "UNIT",
      type: "string",
      headerName: "Unit",

      width: 70,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Unit
        </div>
      ),
    },
    {
      field: "RATE",
      accessor: "RATE",
      type: "number",
      headerName: "Rate",

      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white   text-center" style={{ fontSize: 13 }}>
          Rate
        </div>
      ),
    },
    {
      field: "IQNTY",
      accessor: "IQNTY",
      type: "number",
      headerName: "Issue",

      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white   text-center" style={{ fontSize: 13 }}>
          Quantity
        </div>
      ),
    },

    {
      field: "RQNTY",
      accessor: "RQNTY",
      type: "number",
      headerName: "Return",

      headerClassName: "super-app-theme--header",
      width: 60,
      editable: true,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white   text-center" style={{ fontSize: 13 }}>
          Return Qnty
        </div>
      ),
    },

    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerName: "Amount",

      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white   text-center" style={{ fontSize: 13 }}>
          Amount
        </div>
      ),
    },
  ];

  return columns;
};
