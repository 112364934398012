import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const PrintItemwisePurchaseColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      headerAlign: "center",
      width: 50,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Code
        </div>
      ),
    },

    {
      field: "DEP_NAME",
      accessor: "DEP_NAME",
      type: "string",
      headerAlign: "center",

      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Department
        </div>
      ),
    },
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      headerAlign: "center",

      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Item
        </div>
      ),
    },

    {
      field: "IQNTY",
      accessor: "IQNTY",
      type: "string",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Issue
        </div>
      ),
    },
    {
      field: "RQNTY",
      accessor: "RQNTY",
      type: "string",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Return
        </div>
      ),
    },
    {
      field: "RATE",
      accessor: "RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Rate
        </div>
      ),
    },
    {
      field: "IAMOUNT",
      accessor: "IAMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Issue Amount
        </div>
      ),
    },

    {
      field: "RAMOUNT",
      accessor: "RAMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Return Total
        </div>
      ),
    },
  ];
  return columns;
};
