import React from "react";
import Header from "./mobilesignup/header";
import { useNavigate } from "react-router-dom";

const TermAndCondition = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        flex: 1,
      }}
      className="bg-gray-50 w-screen pt-[850px]  flex flex-col justify-center items-center"
    >
      <div className=" bg-gray-100 h-full  container">
        <Header navigate={navigate} />

        <div>
          <div className=" px-4  ">
            <div className="text-3xl font-bold text-center mb-6">
              Terms & Conditions
            </div>
            <div className="text-gray-500 text-center mb-10">
              Welcome to Retailxapp! These Terms & Conditions ("Terms") govern
              your access to and use of the Retailxapp web application,
              services, and features. By using Retailxapp or its associated
              platforms, you agree to comply with and be bound by these Terms.
              If you do not agree, you may not access or use the platform.
            </div>

            <div className="">
              <div>
                <h2 className="text-lg font-semibold mb-2">
                  1. Use of the Platform
                </h2>
                <ul className="list-disc ml-6 text-gray-600 space-y-2">
                  <li>
                    <strong>Eligibility:</strong> Retailxapp is intended for
                    businesses and individuals operating in the retail industry.
                    By using the platform, you confirm that you are legally
                    authorized to do so.
                  </li>
                  <li>
                    <strong>License:</strong> You are granted a non-exclusive,
                    non-transferable, and revocable license to use Retailxapp
                    for its intended purposes.
                  </li>
                  <li>
                    <strong>Associated Platform:</strong> Retailxcross.com is
                    Retailxapp's official online ordering platform and is
                    governed by these Terms.
                  </li>
                  <li>
                    <strong>Restrictions:</strong> You agree not to misuse the
                    platform, including but not limited to:
                    <ul className="list-disc ml-6">
                      <li>Reverse engineering or modifying the software.</li>
                      <li>
                        Sharing your account credentials with unauthorized
                        individuals.
                      </li>
                      <li>
                        Using the platform for unlawful or unauthorized
                        purposes.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div>
                <h2 className="text-lg font-semibold mb-2">
                  2. Account and Security
                </h2>
                <ul className="list-disc ml-6 text-gray-600 space-y-2">
                  <li>
                    <strong>Account Registration:</strong> You must provide
                    accurate and complete information when registering for an
                    account.
                  </li>
                  <li>
                    <strong>Account Responsibility:</strong> You are solely
                    responsible for maintaining the confidentiality of your
                    login credentials and any activities under your account.
                    Notify us immediately in case of unauthorized access or
                    security breaches.
                  </li>
                </ul>
              </div>

              <div>
                <h2 className="text-lg font-semibold mb-2">
                  3. Billing and Payment
                </h2>
                <ul className="list-disc ml-6 text-gray-600 space-y-2">
                  <li>
                    <strong>Subscription Fees:</strong> Retailxapp operates on a
                    subscription basis. All fees are due in accordance with the
                    chosen plan.
                  </li>
                  <li>
                    <strong>Refund Policy:</strong> Subscription fees are
                    non-refundable except as required by applicable law.
                  </li>
                  <li>
                    <strong>Payment Method:</strong> You must provide a valid
                    payment method. Failure to pay may result in suspension or
                    termination of services.
                  </li>
                </ul>
              </div>

              {[
                {
                  title: "4. Data Privacy",
                  content: [
                    "Retailxapp collects and processes data in accordance with its Privacy Policy.",
                    "We implement industry-standard measures to protect your data but cannot guarantee complete security.",
                    "All data you input into the platform remains your property. However, we may use aggregated and anonymized data for analytics and service improvements.",
                  ],
                },
                {
                  title: "5. Intellectual Property",
                  content: [
                    "Retailxapp and its associated platform, Retailxcross.com, including their features, design, and content, are protected by intellectual property laws. All rights are reserved by genius office.",
                    "The names Retailxapp, Retailxcross.com, and their logos may not be used without prior written consent.",
                  ],
                },
                {
                  title: "6. Limitation of Liability",
                  content: [
                    'Retailxapp is provided on an "as-is" and "as-available" basis.',
                    "We are not liable for any indirect, incidental, or consequential damages arising from your use of the platform.",
                    "Your sole remedy for dissatisfaction with the platform is to discontinue its use.",
                  ],
                },
                {
                  title: "7. Termination",
                  content: [
                    "We reserve the right to suspend or terminate your access to Retailxapp or Retailxcross.com at any time, without notice, if you violate these Terms or engage in unlawful activities.",
                    "Upon termination, your access to all data on the platform will be revoked.",
                  ],
                },
                {
                  title: "8. Governing Law",
                  content: [
                    "These Terms are governed by and construed in accordance with the laws of India.",
                    "All disputes are subject to the exclusive jurisdiction of the courts in Jalandhar.",
                  ],
                },
                {
                  title: "9. Amendments",
                  content: [
                    "We reserve the right to modify these Terms at any time. Changes will be communicated through the platform or via email.",
                    "Your continued use of Retailxapp and Retailxcross.com signifies acceptance of the updated Terms.",
                  ],
                },
                {
                  title: "10. Contact Us",
                  content: [
                    "genius office",
                    "Jalandhar, India",
                    "Website: www.geniusoffice.com",

                    "Email: support@geniusoffice.com",
                    "Website: www.retailxapp.com",
                  ],
                },
              ].map((section, idx) => (
                <div key={idx}>
                  <h2 className="text-lg font-semibold mb-2">
                    {section.title}
                  </h2>
                  <ul className="list-disc ml-6 text-gray-600 space-y-2">
                    {section.content.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermAndCondition;
