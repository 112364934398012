import React, { useRef } from "react";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import "./printConte.css";

const PrintSticker = () => {
  const printRef = useRef();

  return (
    <div id="main">
      {/* Content to Print */}
      <div
        ref={printRef}
        id="print-component-c"
        style={{
          width: "150px", // Adjust to thermal label width (e.g., 4x6 or 2x2)
          height: "auto",
          border: "1px solid #000",
          textAlign: "center",
          fontFamily: "Arial",
          fontSize: "10px",
        }}
      >
        <div>Company Name</div>
        <div>
          <Barcode
            displayValue={false}
            value="123456789"
            width={1}
            height={20}
            margin={0}
            marginLeft={10}
          />
        </div>
        <div>Example Product</div>
        <div>Price: ₹499</div>
      </div>

      {/* Print Button */}
      <ReactToPrint
        trigger={() => <button>Print Barcode Sticker</button>}
        content={() => printRef.current}
        pageStyle={`
          @media print {
            @page {
              size: 4in 6in; /* Adjust to the size of your thermal label */
              margin: 0;
            }
            body {
              margin: 0;
              padding: 0;
            }
            body * {
              visibility: hidden;
            }
            #print-component-c {
              visibility: visible;
              width: 150px; /* Adjust to your label width */
              height: auto;
              font-family: Arial, sans-serif;
              font-size: 10px;
              text-align: center;
              margin: 0;
              padding: 0;
              page-break-before: always; /* Prevent page break before the content */
            }
          }
        `}
      />
    </div>
  );
};

export default PrintSticker;
