import React, { useEffect, useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import QZ from "qz-tray";
import {
  connectPrinterAndList,
  generatePDF,
  generateThermalPDF,
} from "./function";
import { dummyBill } from "./common";
import { PrintButton } from "../utility/component/buttons";
const BillingSettingsModal = ({
  open,
  printData,
  onClose,
  onClickPrintBill,
}) => {
  const defaultPrinter = localStorage.getItem("printer");
  const defaultSize = localStorage.getItem("paperSize");
  const [printSize, setPrintSize] = useState(defaultSize ? defaultSize : "A4");
  const [printer, setPrinter] = useState(
    defaultPrinter ? defaultPrinter : "Default Printer"
  );
  const data = printData[0] ? printData : dummyBill;
  console.log(data);
  const [findPrinters, setFindPrinters] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [pdfUrl, setPdfUri] = useState("");
  useEffect(() => {
    if (open) {
      connectPrinterAndList()
        .then((pr) => {
          setFindPrinters(false);

          setPrinters(pr);
        })
        .catch((e) => {
          console.log(e);
          setFindPrinters(true);
        });
    }
  }, [open]);

  useEffect(() => {
    if (printSize === "58-210") {
      const getTestThermalPrint = async () => {
        const pdfData = await generateThermalPDF(false, data);

        return pdfData;
      };
      getTestThermalPrint().then((d) => {
        setPdfUri(d);
      });
    }
    if (printSize === "A4") {
      const getTestThermalPrint = async () => {
        const pdfData = await generatePDF(false, data);

        return pdfData;
      };
      getTestThermalPrint().then((d) => {
        setPdfUri(d);
      });
    }
  }, [printSize, printData]);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          backgroundColor: "white",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          borderRadius: 8,
        }}
      >
        <ModalHeaderStyle onClose={onClose} header={"Billing Setting"} />
        <div className="p-4 flex">
          <div>
            {pdfUrl ? (
              <iframe
                src={
                  pdfUrl.startsWith("data:application/pdf")
                    ? pdfUrl
                    : `data:application/pdf;base64,${pdfUrl}`
                }
                title="Bill Preview"
                width="100%"
                height="300px"
                style={{ border: "none", marginTop: 16 }}
              ></iframe>
            ) : (
              <p></p>
            )}
          </div>
          <div className="ml-4">
            <label>Paper Size:</label>
            <select
              value={printSize}
              onChange={(e) => {
                localStorage.setItem("paperSize", e.target.value);
                setPrintSize(e.target.value);
              }}
              className="border border-gray rounded-sm"
              style={{ width: "100%", padding: 8, marginTop: 8 }}
            >
              <option value="A4">A4</option>
              <option value="58-210">58 * 210mm </option>
            </select>

            <label style={{ marginTop: 16, display: "block" }}>Printer:</label>
            <select
              value={printer}
              className="border border-gray rounded-sm"
              onChange={(e) => {
                localStorage.setItem("printer", e.target.value);

                setPrinter(e.target.value);
              }}
              style={{ width: "100%", padding: 8, marginTop: 8 }}
            >
              {printers.length > 0 ? (
                printers.map((p, index) => (
                  <option key={index} value={p}>
                    {p}
                  </option>
                ))
              ) : (
                <option value="Default Printer">Default Printer</option>
              )}
            </select>
            {findPrinters && (
              <div className="flex justify-end text-sm text-blue-500">
                <button
                  onClick={() => {
                    if (open) {
                      connectPrinterAndList()
                        .then((pr) => {
                          setPrinters(pr);
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }
                  }}
                >
                  Refresh Printer
                </button>
              </div>
            )}

            {onClickPrintBill &&
            printData[0] &&
            defaultPrinter &&
            defaultSize ? (
              <div className="mt-2 flex justify-end">
                <PrintButton
                  onClick={() => {
                    onClickPrintBill(printData);
                  }}
                />
              </div>
            ) : null}

            <Box
              style={{
                marginTop: 16,
                display: "flex",
                justifyContent: "flex-end",
              }}
            ></Box>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default BillingSettingsModal;
